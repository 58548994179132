import styled from 'styled-components'
import { Colors } from '../../../colours'
import { Breakpoints, Pad } from '../../../breakpoints'
import { Body400, Display5 } from '../../../typography'
import { Link } from 'react-router-dom'
import { Arrow } from '../../../brand'
import { Tag } from '../../tags'
import { Fonts } from '../../../fonts'

export const Projects = {
  Wrapper: styled.div`
    display: flex;
    flex-direction:column;
    margin:0 0 10vh;
    ${Breakpoints.mobile} {
      width: 100vw;
      margin-left:-${Pad};
    }
  `,
  Project: styled.div`
    display:flex;
    justify-content: space-between;
    border-bottom: solid 1px ${Colors.black};
    padding:2rem 0;
    gap:20%;
    &:first-of-type {
      border-top:solid 1px ${Colors.black};
    }
    ${Breakpoints.mobile} {
      flex-direction:column;
      padding:2rem ${Pad}
    }
    ${Breakpoints.medium} {
      flex-direction:column;
      gap:1rem;
    }
    ${Breakpoints.tablet} {
      gap:10%;
    }
  `,
  Main: styled.div`
    display:flex;
    flex-direction:column;
    flex:5;
    gap:1rem;
    ${Breakpoints.mobile} {
      flex:1;
    }
    ${Breakpoints.medium} {
      flex:3;
    }
  `,
  Link: styled(Link)`
    display:flex;
    gap:0.75rem;
    color: ${Colors.light};
    align-items:center;
    text-decoration: none;
    ${Display5} {
      transition: all 200ms;
    }
    &:hover,
    &:active {
      ${Display5} { color: ${Colors.black};}
      ${Arrow} {g {fill: ${Colors.black};}}
    }
    ${Breakpoints.mobile} {
      ${Arrow} {
        flex-grow:1;
      }
    }
    ${Breakpoints.medium} {
      ${Display5} {
        font-size:10vw;
      }
    }
    ${Breakpoints.tablet} {
      ${Display5} {
        font-weight:400;
      }
    }
  `,
  Descript: styled(Body400)`
    color: ${Colors.black};
    ${Breakpoints.mobile} {
      display:none;
    }
  `,
  Infos: styled.div`
    display:flex;
    flex-direction:column;
    flex:2;
    ${Breakpoints.mobile} {
      flex:1;
    }
    ${Breakpoints.medium} {
      gap:0.25rem;
    }
  `,
  Details: styled.div`
    display: flex;
    justify-content: space-between;
    color: ${Colors.black};
    text-transform: capitalize;
    ${Breakpoints.mobile} {
      justify-content: flex-start;
      gap:1rem;
    }
    ${Breakpoints.medium} {
      justify-content: flex-start;
      gap:2rem;
    }
  `,
  Tags: styled.div`
    font-family:${Fonts.body};
    flex-grow:1;
    display:flex;
    flex-direction:column;
    justify-content: center;
    gap:3%;
    ${Breakpoints.mobile} {
      flex-direction: row;
      justify-content: flex-start;
      margin:0.25rem 0 0;
      ${Tag} {
        width: max-content;
      }
    }
    ${Breakpoints.medium} {
      flex-direction: row;
      justify-content: flex-start;
    }
  `
}