import styled from 'styled-components'
import { Colors } from './colours'
// SVG
// -- logos
import { ReactComponent as Branding } from './img/brand/branding-logo.svg'
import { ReactComponent as Props } from './img/brand/props-logo.svg'
import { ReactComponent as UI } from './img/brand/ui-logo.svg'
import { ReactComponent as Main } from './img/brand/main-logo.svg'
// -- roses
import { ReactComponent as Half } from './img/brand/half-rose.svg'
import { ReactComponent as Tight } from './img/brand/tight-rose.svg'
import { ReactComponent as Wide } from './img/brand/wide-rose.svg'
// -- tags
import { ReactComponent as TagProp } from './img/brand/props.svg' 
import { ReactComponent as TagBranding } from './img/brand/branding.svg' 
import { ReactComponent as TagUI } from './img/brand/ux-ui.svg' 
// -- misc
import { ReactComponent as Stamp } from './img/brand/stamp-logo.svg'
import { ReactComponent as Vertical } from './img/brand/vertical-logo.svg'
import { ReactComponent as Swatch } from './img/brand/swatches.svg'
import { ReactComponent as CrossSvg } from './img/brand/cross.svg'
import { ReactComponent as ArrowSvg } from './img/brand/arrow.svg'

// Logos
export const BrandingLogo = styled(Branding)`
	g { fill: ${(props) => props.fill}; transition: all 200ms;}
	&:hover {g {fill: ${(props) => (props.hover ? props.hover : props.fill)};}}
`
export const PropsLogo = styled(Props)`
	g {fill: ${(props) => props.fill};transition: all 200ms;}
	&:hover {g {fill: ${(props) => (props.hover ? props.hover : props.fill)};}}
`
export const UILogo = styled(UI)`
	g { fill: ${(props) => props.fill}; transition: all 200ms;}
	&:hover {g {fill: ${(props) => (props.hover ? props.hover : props.fill)};}}
`
export const MainLogo = styled(Main)`
	g {fill: ${(props) => props.fill};transition: all 200ms;}
	&:hover {g {fill: ${(props) => (props.hover ? props.hover : props.fill)};}}
`

// Graphics
export const HalfRose = styled(Half)`
	g {fill: ${(props) => props.fill};transition: all 200ms;}
	&:hover {g {fill: ${(props) => (props.hover ? props.hover : props.fill)};}}
`
export const TightRose = styled(Tight)`
	g {fill: ${(props) => props.fill};transition: all 200ms;}
	&:hover {g {fill: ${(props) => (props.hover ? props.hover : props.fill)};}}
`
export const WideRose = styled(Wide)`
	g {fill: ${(props) => props.fill};transition: all 200ms;}
	&:hover {g {fill: ${(props) => (props.hover ? props.hover : props.fill)};}}
`
export const StampLogo = styled(Stamp)`
	g {fill: ${(props) => props.fill};transition: all 200ms;}
	&:hover {g {fill: ${(props) => (props.hover ? props.hover : props.fill)};}}
`
export const VerticalLogo = styled(Vertical)`
	g {fill: ${(props) => props.fill};transition: all 200ms;}
	&:hover {g {fill: ${(props) => (props.hover ? props.hover : props.fill)};}}
`
export const Swatches = styled(Swatch)`
	g {
		&.orange { fill:${Colors.orange} }
		&.light { fill:${Colors.light} }
		&.black { fill:${Colors.black} }
		&.grey { fill:${Colors.grey} }
	}
`
export const Cross = styled(CrossSvg)`
	g {stroke: ${(props) => props.fill};transition: all 200ms;}
	&:hover {g {stroke: ${(props) => (props.hover ? props.hover : props.fill)};}}
`
export const Arrow = styled(ArrowSvg)`
	g {fill: ${(props) => props.fill};transition: all 200ms;}
	&:hover {g {fill: ${(props) => (props.hover ? props.hover : props.fill)};}}
`

// Tags
export const PropTag = styled(TagProp)`
	g {fill: ${(props) => props.fill};transition: all 200ms;}
`
export const BrandingTag = styled(TagBranding)`
	g {fill: ${(props) => props.fill};transition: all 200ms;}
`
export const UITag = styled(TagUI)`
	g {fill: ${(props) => props.fill};transition: all 200ms;}
`