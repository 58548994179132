// Library
import { Colors } from '../../../colours'
import { Fonts } from '../../../fonts'
import { Breakpoints } from '../../../breakpoints'
// Styles
import styled from 'styled-components'
// Components

const FormComponent = {
  Form: styled.form`
    flex:1.25;
    width: 100%;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap:3%;
    padding: 7vh 0;
    ${Breakpoints.tablet} {
      order:1;
      flex:initial;
    }
  `,
  Fieldset: styled.fieldset`
    width: 100%;
    border: none;
    display: flex;
    flex-direction: column;
    gap:0.5rem;
  `,
  Label: styled.label`
    font-size:0.95rem;
    font-weight:300;
    text-transform: capitalize;
    &.required {&::after { content: "*"; margin-left:0.25rem; }}
  `,
  Input: styled.input.attrs({type:'text'})`
    border:none;
    height: 2.75rem;
    padding:0 1rem;
    border-radius:3px;
    background: ${Colors.light};
    font-family: ${Fonts.body};
    font-weight:500;
    font-size:1.25rem;
    transition: all 200ms;
    &:focus {
      color: ${Colors.light};
      background:${Colors.orange};
      outline:none;
    }
  `,
  Textarea: styled.textarea`
    height: 100%;
    border:none;
    border-radius:3px;
    background: ${Colors.light};
    font-family: ${Fonts.body};
    font-weight:500;
    font-size:1rem;
    transition: all 200ms;
    padding:0.5rem;
    &:focus {
      color: ${Colors.light};
      background:${Colors.orange};
      outline:none;
    }
  `,
  Submit: styled.input.attrs({type:'submit'})`
    height: 4vw;
    background:none;
    border:none;
    font-family: ${Fonts.display};
    color: ${Colors.light};
    text-transform: uppercase;
    font-size:3vw;
    font-weight:500;
    letter-spacing: 0.25rem;
    line-height:1;
    cursor:pointer;
    transition: all 200ms;
    &:hover { color: ${Colors.orange}; }
    ${Breakpoints.mobile} {
      height: 2.75rem;
      font-family: ${Fonts.body};
      font-size:1.25rem;
      background: ${Colors.orange};
      width: 100%;
      letter-spacing: 0;
      border-radius:3px;
      &:hover {color: inherit;}
    }
    ${Breakpoints.medium} {
      height: 2.75rem;
      font-family: ${Fonts.body};
      font-size:1.25rem;
      background: ${Colors.orange};
      width: 100%;
      letter-spacing: 0;
      border-radius:3px;
    }
  `
}

const Fieldset = (props) => (
  <FormComponent.Fieldset>
    <FormComponent.Label className={props.required}>{props.label}</FormComponent.Label>
    <FormComponent.Input />
  </FormComponent.Fieldset>
)

const Textarea = (props) => (
  <FormComponent.Fieldset style={{flexGrow:'1'}}>
    <FormComponent.Label className={props.required}>{props.label}</FormComponent.Label>
    <FormComponent.Textarea />
  </FormComponent.Fieldset>
)

const Form = () => (
  <FormComponent.Form>
    <Fieldset label='name' required='required'/>
    <Fieldset label='email' required='required'/>
    <Fieldset label='company/studio' required=''/>
    <Fieldset label='website' required=''/>
    <Textarea label='message' required='required'/>
    <FormComponent.Submit value='send message'/>
  </FormComponent.Form>
)

export default Form