import { BannerBtn, BannerComponent, BannerLeft, BannerRight, BannerLink } from "./style"
import { Display1, Display3, Display5 , Body500 } from "../../typography"
import { WideRose, Arrow } from "../../brand"

const BannerButton = (props) =>  (
  <BannerBtn hover={props.hover}>
    <WideRose fill={props.color}/>
    <BannerLink to={props.link} color={props.color}>
      <Display5 hover={props.hover}>{props.text}</Display5>
      <Arrow fill={props.color}/>
    </BannerLink>
  </BannerBtn>
)

const Banner = (props) => (
  <BannerComponent background={props.background} color={props.color} >
    <BannerLeft>
      <Display3>you have a project coming?</Display3>
      <Display1>Let's Talk!</Display1>
      <Body500>Send me some information and details about the projects, and about what you need, and I'll make sure to get back to you quickly with a quote and good dose of entusiasm</Body500>
    </BannerLeft>
    <BannerRight>
      <BannerButton text='by email' link='/contact' color={props.color} hover={props.hover}/>
      <BannerButton text='by filling the form' link='/contact' color={props.color} hover={props.hover}/>
    </BannerRight>
  </BannerComponent>
)

export default Banner