// Default Nav
import DefaultFooter from "./default";
// Mobile Nav
import MobileFooter from './mobile';

const Footer = (props) => (
  <>
    <MobileFooter color={props.color} hover={props.hover} mobile={props.mobile}/>
    <DefaultFooter color={props.color} hover={props.hover} />
  </>
)

export default Footer