// Global Styles
import GlobalStyles from "./globalStyles"
import './fonts/morganite/stylesheet.css'

import Pages from "./routes"


 const App = () => (
    <>
    <Pages />
    <GlobalStyles />
    </>
  )


export default App;
