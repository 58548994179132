import styled from "styled-components"
import { Breakpoints, Pad } from "../../../breakpoints"
import { Display1, Body400, Body600 } from "../../../typography"
import { Swatches, HalfRose, TightRose, WideRose } from '../../../brand'
import { LgButton } from "../../../buttons"

export const HeaderComponent = styled.header`
  width: 100%;
  height:110vh;
  display:flex;
  padding:10vh 0 0;
  gap:4%;
  ${Breakpoints.mobile} {
    padding:15vh 0 5vh;
    flex-direction:column;
    height: max-content;
  }
  ${Breakpoints.medium} {
    padding:20vh 0 0;
    flex-direction:column-reverse;
    height: max-content;
    position:relative;
  }
  ${Breakpoints.smDesktop} {
    height:80vh;
  }
  ${Breakpoints.tablet} {
    gap:2%;
    height:80vh;
  }
`

export const FirstCol = styled.div`
  flex:1.5;
  position: relative;
  ${Display1} {
    position:relative;
    z-index:1;
    ${Breakpoints.mobile} {
      font-size:17vw;
      font-weight:500;
    }
    ${Breakpoints.medium} { font-size:18vw; }
    ${Breakpoints.tablet} { font-size: 10vw;}
      }
  ${Breakpoints.tablet} {
      display: flex;
      flex-flow: column-reverse;
      justify-content: space-between;
    }

`

export const LastCol = styled.div`
  height:50%;
  flex:1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${Swatches} {
    align-self:flex-end;
    ${Breakpoints.mobile} {
      position:absolute;
      bottom:4vh;
      left:${Pad};
    }
    ${Breakpoints.medium} {
      position:absolute;
      bottom:-6vh;
    }
    ${Breakpoints.tablet} {
      position:absolute;
      z-index:5;
      top:20%;
      width:10vw;
    }
  }
  ${Breakpoints.mobile} {
    gap: 2vh;
    align-items: flex-start;
    justify-content: flex-start;
  }
  ${Breakpoints.medium} {
    flex-direction: row;
    flex-wrap:wrap;
  }
  ${Breakpoints.smDesktop} {
    height:85%;
  }
  ${Breakpoints.tablet} {
    height:80%;
    justify-content: space-around;
  }

`

export const Tag = styled(Body400)`
  padding-left:10%;
  ${Breakpoints.mobile} {
    padding-left:0;
    width: 75%;
    font-size: 0.8rem;
  }
  ${Breakpoints.medium} {
    order:1;
    width: 100%;
    padding-left:0;
    margin:0 0 1.5rem;
  }
  ${Breakpoints.tablet} {
    padding-left:0;
  }
  ${Breakpoints.smDesktop} {
    padding-left:0;
  }
`

export const BackgroundImg = styled.img`
  position: absolute;
  top:20%;
  left:-${Pad};
  width: 100%;
  height:auto;
  ${Breakpoints.mobile} {
    display: none;
  }
  ${Breakpoints.medium} {
    display: none;
  }
  ${Breakpoints.tablet} {
    width:95%;
    height:auto;
    position:relative;
    transform:translateY(-30vh);
  }
  ${Breakpoints.smDesktop} {
    top:10%;
  }
`

export const Graphics = styled.div`
  width: 70%;
  display:flex;
  justify-content: space-between;
  position:relative;
  gap:15%;
  ${HalfRose},${TightRose},${WideRose} {
    flex:1;
  }
  .cross {
    width: 1rem;
  }
  .top-left {
    position:absolute;
    top:-20%;
    left:30%;
  }
  .top-right {
    position:absolute;
    top:-20%;
    left:66%;
  }
  .bottom-left {
    position:absolute;
    bottom:-20%;
    left:30%;
  }
  .bottom-right {
    position:absolute;
    bottom:-20%;
    left: 66%;
  }
  ${Breakpoints.mobile} {
    width: 90%;
    margin:5vh 0;
  }
  ${Breakpoints.medium} {
    width: 90%;
  }
  ${Breakpoints.tablet} {
    width: 100%;
  }
  ${Breakpoints.smDesktop} {
    width: 100%;
  }
`

export const Buttons = styled.div`
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: space-between;
  ${LgButton} {
    margin-right:10%;
  }
  ${Breakpoints.mobile} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:1vh;
  }
  ${Breakpoints.medium} {
    margin:5vh 0;
    width:90%;
  }
  ${Breakpoints.tablet} {
    flex-direction:column;
    align-items: flex-start;
    gap:1rem
  }
  ${Breakpoints.smDesktop} {
    flex-direction: column;
    align-items: flex-start;
    gap:1rem;
  }
`

export const Intro = styled(Body600)`
  padding:100px 10% 0 0;
  ${Breakpoints.mobile} {
    padding:100px 0 0 0;
  }
`