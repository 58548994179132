import styled from 'styled-components'
import { Colors } from '../../../colours'
import { Breakpoints } from '../../../breakpoints'

export const MobileFooterComponent = {
  Wrapper: styled.footer`
    width: 100%;
    height:20vh;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    padding:0 0 3vh;
    ${Breakpoints.tablet} {display:none }
    ${Breakpoints.smDesktop} {display:none }
    ${Breakpoints.lgDesktop} {display:none }
    ${Breakpoints.wide} {display:none }
  `,
  Socials: styled.div`
    display: flex;
    justify-content: space-between;
    padding-right:12%;
    ${Breakpoints.medium} {
      width:80%;
      padding-right:0;
      margin:0 0 1rem;
    }    
  `,
  SocialLink: styled.a`
    color:${props => props.color};
    text-decoration: none;
    font-size:0.8rem;
    line-height:0.75;
    letter-spacing: 0.05rem;
    width: 40px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: solid 1px ${props => props.color};
    &:active {
      background: ${props => props.color};
      color:${Colors.light};
    }
    ${Breakpoints.medium} {
      width:50px;
      height:50px;
      font-size:1rem;
    }
  `,
  Email: styled.a`
    color:${props => props.color};
    font-weight:600;
    font-size:1.1rem;
    text-decoration:none;
    ${Breakpoints.medium} {font-size: 1.5rem;}
  `,
  Mentions: styled.div`
  `,
  Legal: styled.div`
    display:flex;
    gap:0.75rem;
    ${Breakpoints.medium} {
      gap:2.25rem;
    }
  `,
  LegalLink: styled.a`
    font-size:0.7rem;
    font-weight:300;
    color: ${props => props.color};
    text-decoration:none;
  `,
  Copy: styled.p`
    font-size:0.7rem;
    font-weight:300;
    color: ${props => props.color};
    line-height:2.25;
    ${Breakpoints.medium} {
      order:-1;
    }
  `
}