import { createGlobalStyle } from 'styled-components'
import { Colors } from './colours'
import { Breakpoints, Pad } from './breakpoints'

const GlobalStyles = createGlobalStyle`

  * {
    padding:0;
    margin:0;
    box-sizing: border-box;
  }

  ::selection {
    background: ${Colors.grey};
    color: ${Colors.black};
  }

  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
		overflow-x:hidden;
  }

	img {
		object-fit: cover;
	}

  section,
  nav {
    ${Breakpoints.mobile} {padding:0 ${Pad.mobile}}
    ${Breakpoints.medium} {padding:0 ${Pad.medium}}
    ${Breakpoints.tablet} {padding:0 ${Pad.tablet}}
    ${Breakpoints.smDesktop} {padding:0 ${Pad.smDesktop}}
    ${Breakpoints.lgDesktop} {padding:0 ${Pad.lgDesktop}}
    ${Breakpoints.wide} {padding:0 ${Pad.wide}}
  }

`

export default GlobalStyles
