import styled from 'styled-components'
import { Display4 } from '../../../typography'
import { Colors } from '../../../colours'
import { Breakpoints } from '../../../breakpoints'

export const ProcessComponent = styled.div`
  margin: 20vh 0;
  ${Breakpoints.mobile} { margin:10vh 0;}
  ${Breakpoints.tablet} { margin:10vh 0;}
`

export const ProcessHeading = styled(Display4)`
  color: ${Colors.orange};
  padding:10vh 0 5vh;
  span {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${Colors.orange};
  }
  ${Breakpoints.mobile} {
    font-size:20vw;
    span {
      -webkit-text-fill-color: ${Colors.black};
      -webkit-text-stroke-width: none;
      -webkit-text-stroke-color: transparent;
    }
  ${Breakpoints.tablet} {
    span {
      -webkit-text-stroke-width: 1px;
    }
  }}
`

export const ProcessContent = styled.div`
  display: flex;
  gap:7vw;
  ${Breakpoints.mobile} { flex-direction:column; }
  ${Breakpoints.medium} { flex-direction:column; }
  ${Breakpoints.tablet} { gap:4vw;}
`