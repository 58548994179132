import { HomeServicesComponents, ServicesTitle, ServicesDetails, ServiceInfo, ServiceLogo, ServiceDescript } from "./style"
import { Display1, Body600 } from "../../../typography"
import { PropsLogo, BrandingLogo, UILogo } from "../../../brand"
import { Colors } from "../../../colours"

const HomeServices = () => (
  <HomeServicesComponents>
    <ServicesTitle>
      <Display1>a multiverse of services</Display1>
      <Body600>Proin pharetra ullamcorper netus felis turpis eu interdum adipiscing dui. A cursus id dignissim adipiscing adipiscing adipiscing. Eu mi lacinia in placerat malesuada libero, ac. Enim nec et aliquam vulputate aliquet tristique arcu pellentesque neque nisl.</Body600>
    </ServicesTitle>
    <ServicesDetails>
      <ServiceInfo>
        <ServiceLogo.Wrapper>
          <PropsLogo fill={Colors.orange} className="logo"/>
          <ServiceLogo.Name>Background props</ServiceLogo.Name>
        </ServiceLogo.Wrapper>
        <ServiceDescript>Proin pharetra ullamcorper netus felis turpis eu interdum adipiscing dui. A cursus id dignissim adipiscing adipiscing adipiscing. Eu mi lacinia in placerat malesuada libero, ac. Enim nec et aliquam vulputate aliquet tristique. </ServiceDescript>
      </ServiceInfo>
      <ServiceInfo>
        <ServiceLogo.Wrapper>
          <BrandingLogo fill={Colors.orange} className="logo" />
          <ServiceLogo.Name>Branding</ServiceLogo.Name>
        </ServiceLogo.Wrapper>
        <ServiceDescript>Proin pharetra ullamcorper netus felis turpis eu interdum adipiscing dui. A cursus id dignissim adipiscing adipiscing adipiscing. Eu mi lacinia in placerat malesuada libero, ac. Enim nec et aliquam vulputate aliquet tristique. </ServiceDescript>
      </ServiceInfo>
      <ServiceInfo>
        <ServiceLogo.Wrapper>
          <UILogo fill={Colors.orange} className="logo" />
          <ServiceLogo.Name>UX / UI</ServiceLogo.Name>
        </ServiceLogo.Wrapper>
        <ServiceDescript>Proin pharetra ullamcorper netus felis turpis eu interdum adipiscing dui. A cursus id dignissim adipiscing adipiscing adipiscing. Eu mi lacinia in placerat malesuada libero, ac. Enim nec et aliquam vulputate aliquet tristique. </ServiceDescript>
      </ServiceInfo>
    </ServicesDetails>
  </HomeServicesComponents>
)

export default HomeServices