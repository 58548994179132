import { Colors } from "../../../colours"
import { SocialLink, SocialLinksComponent, SocialsComponent, SocialsHeader, SocialsText, SocialsTitle } from "./style"
import { TightRose, Cross } from "../../../brand"

export const SocialLinks = () => (
  <SocialLinksComponent>
    <SocialLink href='#'>instagram</SocialLink>
    <Cross fill={Colors.light}/>
    <SocialLink href='#'>facebook</SocialLink>
    <Cross fill={Colors.light}/>
    <SocialLink href='#'>behance</SocialLink>
    <Cross fill={Colors.light}/>
    <SocialLink href='#'>dribbble</SocialLink>
    <Cross fill={Colors.light}/>
    <SocialLink href='#'>linkedin</SocialLink>
  </SocialLinksComponent>
)

const Socials = () => (
  <SocialsComponent>
    <SocialsHeader>
      <SocialsTitle>Find <span>Pseudo design</span> on social platforms</SocialsTitle>
      <TightRose fill={Colors.grey} />
      <TightRose fill={Colors.grey} />
      <TightRose fill={Colors.grey} />
      <TightRose fill={Colors.grey} />
    </SocialsHeader>
    <SocialsText>Proin pharetra ullamcorper netus felis turpis eu interdum adipiscing dui. A cursus id dignissim adipiscing adipiscing adipiscing eumi lacinia in placerat.</SocialsText>
    <SocialLinks />
  </SocialsComponent>
)

export default Socials