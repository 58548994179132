import styled from 'styled-components'
import { Fonts } from './fonts'
import { Breakpoints } from './breakpoints'

// Display Headings
export const Display1 = styled.h1`
	font-family: ${Fonts.display};
  font-size:10vw;
  text-transform: uppercase;
  line-height:0.75;
  ${Breakpoints.mobile} {
    font-size:17vw;
  }
  ${Breakpoints.medium} {
    font-size:17vw;
  }
  ${Breakpoints.tablet} {
    font-size:17vw;
  }
`
export const Display2 = styled.h2`
	font-family: ${Fonts.display};
  font-size:9vw;
  text-transform: uppercase;
  line-height:0.75;
`
export const Display3 = styled.h3`
	font-family: ${Fonts.display};
  font-size:8vw;
  text-transform: uppercase;
  line-height:0.75;
  ${Breakpoints.mobile} {
    font-size:14vw;
    font-weight:500;
  }
  ${Breakpoints.medium} {
    font-size:14vw;
    font-weight:500;
  }
`
export const Display4 = styled.h4`
	font-family: ${Fonts.display};
  font-size:7vw;
  text-transform: uppercase;
  line-height:0.75;
  ${Breakpoints.mobile} {
    font-size:17vw;
    line-height:0.85;
  }
  ${Breakpoints.medium} {
    font-size:17vw;
    line-height:0.85;
  }
  ${Breakpoints.tablet} {
    font-size:12vw;
  }
  ${Breakpoints.smDesktop} {
    font-size:12vw;
  }
`
export const Display5 = styled.h5`
	font-family: ${Fonts.display};
  font-size:5vw;
  text-transform: uppercase;
  line-height:0.75;
  font-weight:500;
  ${Breakpoints.mobile} {
    font-size:14vw;
    font-weight:300;
    letter-spacing:0.05rem;
  }
  ${Breakpoints.medium} {
    font-size:14vw;
    font-weight:300;
    letter-spacing:0.05rem;
  }
  ${Breakpoints.tablet} {
    font-size:9vw;
  }
`
// Standard Headings
export const Heading1 = styled.h1`
  font-family:${Fonts.body};
  font-size:60px;
  line-height:1.25;
`
export const Heading2 = styled.h2`
  font-family:${Fonts.body};
  font-size:50px;
  line-height:1.25;
`
export const Heading3 = styled.h3`
  font-family:${Fonts.body};
  font-size:40px;
  line-height:1.25;
`
export const Heading4 = styled.h4`
  font-family:${Fonts.body};
  font-size:30px;
  line-height:1.25;
`
export const Heading5 = styled.h5`
  font-family:${Fonts.body};
  font-size:26px;
  line-height:1.25;
  font-weight: 600;
`
export const Heading6 = styled.h6`
  font-family:${Fonts.body};
  font-size:22px;
  line-height:1.25;
  font-weight: 500;
  ${Breakpoints.mobile} {
    font-size:1.25rem;
  }
`
// Body Paragraphs
export const Body700 = styled.p`
  font-family:${Fonts.body};
  font-size:1.25rem;
  font-weight:500;
  line-height:1.5;
  ${Breakpoints.mobile} {
    font-size:1.15rem;
    line-height:1.4;
  }
`
export const Body600 = styled.p`
  font-family:${Fonts.body};
  font-size:1.1rem;
  font-weight:500;
  line-height:1.5;
  ${Breakpoints.mobile} {
    font-size:1.1rem;
  }
`
export const Body500 = styled.p`
  font-family:${Fonts.body};
  font-weight:300;
  font-size:1rem;
  line-height:1.4;
  letter-spacing: 0.03rem;
  ${Breakpoints.mobile} {
    font-weight:300;
  }
`
export const Body400 = styled.p`
  font-family:${Fonts.body};
  font-weight:300;
  font-size:0.9rem;
  line-height:1.5;
  letter-spacing: 0.05rem;
  ${Breakpoints.mobile} {
    font-size:0.85rem;
    line-height:1.75;
  }
`
export const Body300 = styled.p`
  font-family:${Fonts.body};
  font-weight:200;
  font-size:0.82rem;
  line-height:1.35;
  letter-spacing: 0.04rem;
  ${Breakpoints.mobile} {
    font-size:0.75rem;
    line-height:1.25;
  }
`
