import { ServiceHeaderComponent } from "./style"
import { MainLogo } from "../../../brand"
import { Colors } from "../../../colours"
import { Body700 } from "../../../typography"

const ServiceHeader = () => (
  <ServiceHeaderComponent.Wrapper>
    <MainLogo fill={Colors.orange}/>
    <ServiceHeaderComponent.Content>
      <ServiceHeaderComponent.Heading>Services</ServiceHeaderComponent.Heading>
      <ServiceHeaderComponent.Text>
        <Body700>
        Tortor viverra turpis lectus sociis. Elit nam id hendrerit justo, pellentesque morbi. Sit diam vel blandit ornare purus donec. Integer egestas tincidunt nisl porttitor congue sapien vel ac ut. Malesuada proin sagittis, sem interdum elementum morbi at. Ut sit mi porta curabitur at. In dui integer at nunc et. Mollis amet, sed gravida lacinia amet fusce adipiscing risus. Malesuada vulputate sapien nibh vulputate. Quam at egestas facilisis tortor. Nunc orci, congue ullamcorper lacus, vestibulum hendrerit.
        </Body700>
      </ServiceHeaderComponent.Text>
    </ServiceHeaderComponent.Content>
  </ServiceHeaderComponent.Wrapper>
)

export default ServiceHeader