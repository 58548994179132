// Library
import { Colors } from '../../../colours'
// Styles
import { PropTag, BrandingTag } from '../../../brand'
import { ProjectHeaderComponent } from './style'
// Components


const ProjectHeader = () => (
  <ProjectHeaderComponent.Wrapper>
    <ProjectHeaderComponent.Heading>
      <ProjectHeaderComponent.Title>A project name</ProjectHeaderComponent.Title>
      <ProjectHeaderComponent.Tags>
        <PropTag fill={Colors.light} className='tag' />
        <BrandingTag fill={Colors.light} className='tag' />
      </ProjectHeaderComponent.Tags>
    </ProjectHeaderComponent.Heading>
    <ProjectHeaderComponent.Paragraph>
    Justo, volutpat eros, faucibus sit nunc leo. Cursus quam quis cras quam. Aliquam mauris dictum laoreet tincidunt magna auctor viverra odio. Posuere ac viverra eget aliquet facilisis vitae, tempor accumsan. Blandit aliquam morbi aliquet. Ullamcorper felis metus amet enim. Ornare massa neque tempus arcu gravida at. Maecenas ipsum nunc sem lacus, id gravida.  In nisl pellentesque leo, magna massa aliquam, nibh vulputate sed. Consequat nulla orci aenean tellus. Et facilisis amet vitae ut. In magna purus libero nisi. Elementum molestie neque non ac eu facilisis enim aliquet nunc.
    </ProjectHeaderComponent.Paragraph>
  </ProjectHeaderComponent.Wrapper>
)

export default ProjectHeader