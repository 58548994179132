import styled from 'styled-components'
import { Colors } from '../../colours'
import { Breakpoints } from '../../breakpoints'
import { Display1 } from '../../typography'

export const WorkComponent = { 
  Wrapper: styled.section`
    width: 100vw;
    min-height:100vh;
    height:100%;
    overflow-x: hidden;
    background: ${Colors.orange};
    color: ${Colors.light};
  `,
  Heading: styled(Display1)`
    margin:15vh 0 5vh;
    ${Breakpoints.mobile} {
      font-size:26vw;
    }
  `
}

