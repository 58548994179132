import { HomeComponent } from "./style"
import { Colors } from "../../colours"
// Components
import Nav from '../../components/nav'
import HomeHeader from "../../components/home/header"
import Gallery from "../../components/home/gallery"
import HomeServices from "../../components/home/services"
import Hero from "../../components/home/hero"
import Socials from "../../components/home/socials"
import Banner from "../../components/banner"
import Footer from "../../components/footer"

const Home = () => (
  <HomeComponent.Wrapper>
  
  <Nav color={Colors.light} hover={Colors.orange}/>
  <HomeHeader />
  <HomeComponent.Intro>Sed in diam id quisque proin. Volutpat a id malesuada purus, elementum. Ultrices in purus feugiat cursus hac odio est dolor. Eget tellus nec rhoncus, aliquam sed. Praesent vitae fermentum lacus aenean mauris sem. Sollicitudin accumsan ornare massa maecenas turpis sapien turpis tincidunt. 
  Ornare libero lectus amet purus morbi ut. Turpis purus fermentum enim adipiscing aliquam nibh sit pharetra. Varius vehicula nulla dui volutpat convallis in interdum auctor. Vulputate sollicitudin ante viverra adipiscing enim justo orci tristique. Quis scelerisque cursus proin donec sit. Pellentesque orci faucibus libero sed sit nisi consectetur ac.Proin pharetra ullamcorper netus felis turpis eu interdum adipiscing dui. A cursus id dignissim adipiscing adipiscing adipiscing. Eu mi lacinia in placerat malesuada libero, ac. Enim nec et aliquam vulputate aliquet tristique. </HomeComponent.Intro>
  <Gallery />
  <HomeServices />
  <Hero />
  <Socials />
  <Banner background={Colors.light} color={Colors.black} hover={Colors.orange} />
  <Footer color={Colors.orange} hover={Colors.grey} mobile={Colors.grey}/>
  </HomeComponent.Wrapper> 
)

export default Home

