import { NavComponent, NavLogo} from "./style"

const DefaultNav = (props) =>  (
  <NavComponent.Wrapper>
  <NavComponent.NavLink to="/" color={props.color} hover={props.hover} >home</NavComponent.NavLink>
  <NavComponent.NavLink to="/work" color={props.color} hover={props.hover} >work</NavComponent.NavLink>
  <NavLogo fill={props.color}/>
  <NavComponent.NavLink to="/services" color={props.color} hover={props.hover} >services</NavComponent.NavLink>
  <NavComponent.NavLink to="/contact" color={props.color} hover={props.hover} >contact</NavComponent.NavLink>
  </NavComponent.Wrapper>
)

export default DefaultNav