import styled from 'styled-components'
import { Colors } from '../../colours'
import { Breakpoints } from '../../breakpoints'
import { Body400 } from '../../typography'

export const HomeComponent = {
  Wrapper: styled.section`
    width: 100vw;
    min-height:100vh;
    height:100%;
    overflow-x: hidden;
    background: ${Colors.black};
    color: ${Colors.light};`
    ,
    Intro :styled(Body400)`
      height:100px;
      column-count: 2;
      column-gap:10%;
      color: ${Colors.grey};
      margin:10vh 0;
      ${Breakpoints.mobile} {
        column-count: 1;
        height: max-content;
      }
      ${Breakpoints.medium} {
        column-count: 1;
        height: max-content;
      }
      ${Breakpoints.medium} {
        margin:4vh 0;
      }
  ` 
  }

