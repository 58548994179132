import { GalleryComponent, GalleryContent, GalleryColumn1, GalleryColumn2, GalleryItem, GalleryCaption } from "./style"
import Gallery1 from '../../../img/home/gallery1.jpg'
import Gallery2 from '../../../img/home/gallery2.jpg'
import Gallery3 from '../../../img/home/gallery3.jpg'
import Gallery4 from '../../../img/home/gallery4.jpg'
import Gallery5 from '../../../img/home/gallery5.jpg'


const Gallery = () => (
  <GalleryComponent>
    <GalleryContent>
      <GalleryColumn1>
        <GalleryItem  width='100%'>
          <img src={Gallery1} alt=''/>
          <GalleryCaption>Justo, volutpat eros, faucibus sit nunc. Aliquam mauris dictum laoreet tincidunt. Posuere ac viverra eget aliquet facilisis vitae.</GalleryCaption>
        </GalleryItem>
        <GalleryItem width='60%'>
          <img src={Gallery2} alt=''/>
          <GalleryCaption>Justo, volutpat eros. Aliquam mauris dictum laoreet tincidunt. Posuere ac viverra eget aliquet facilisis vitae.</GalleryCaption>
        </GalleryItem>
        <GalleryItem width='38%'>
          <img src={Gallery3} alt=''/>
          <GalleryCaption>Justo, volutpat eros. Aliquam mauris dictum laoreet tincidunt.</GalleryCaption>
        </GalleryItem>
      </GalleryColumn1>
      <GalleryColumn2>
      <GalleryItem width='100%'>
          <img src={Gallery4} alt=''/>
          <GalleryCaption>Justo, volutpat eros, faucibus sit nunc. Aliquam mauris dictum laoreet tincidunt. Aliquet facilisis vitae.</GalleryCaption>
      </GalleryItem>
      <GalleryItem width='100%'>
          <img src={Gallery5} alt=''/>
          <GalleryCaption>Justo, volutpat eros, faucibus sit nunc. Posuere ac viverra eget aliquet facilisis vitae.</GalleryCaption>
        </GalleryItem>
      </GalleryColumn2>
    </GalleryContent>
  </GalleryComponent>
)

export default Gallery