import styled from 'styled-components'
import { Fonts } from '../../../fonts'
import { Breakpoints, Pad } from '../../../breakpoints'

export const FooterComponent = styled.footer`
  display:flex;
  color: ${props => props.color};
  font-family: ${Fonts.body};
  ${Breakpoints.mobile} {display:none}
  ${Breakpoints.medium} {display:none}
  ${Breakpoints.tablet} {
    padding:5vh 0;
  }
  ${Breakpoints.smDesktop} {padding: 7vh 0;}
  ${Breakpoints.lgDesktop} {padding: 7vh ${Pad.lgDesktop};}
  ${Breakpoints.wide} {padding: 7vh ${Pad.wide};}

`

export const FooterLogo = styled.div`
  flex:1;
  display:flex;
  justify-content: center;
  svg { width: 70px; }
  ${Breakpoints.tablet} {
    display: none;
  }
`

export const FooterLinks = styled.div`
  display:flex;
  flex-flow:column;
  flex-grow:1;
`

export const FooterTop = styled.div`
  gap:2rem;
  flex:2;
  display:flex;
  align-items:center;
  ${Breakpoints.tablet} { gap:1rem; }
`
export const FooterEmail = styled.a`
  width: 40%;
  font-size:1.5rem;
  font-weight:600;
  text-decoration:none;
  color: ${props => props.color};
  transition: all 200ms;
  &:hover {
    color : ${props => props.hover};
  }
  ${Breakpoints.tablet} {
    font-size:1.25rem;
  }
` 

export const FooterCopy = styled.p`
  width: 40%;
  font-size:0.7rem;
  font-weight:300;
  opacity:0.9;
`

export const FooterBottom = styled.div`
  display:flex;
  gap:2rem;
  ${Breakpoints.tablet} { gap:1rem; }
`

export const FooterSocials = styled.div`
  width: 60%;;
  display:flex;
  padding:0.5rem 0;
  justify-content: space-between;
`

export const FooterSocialLink = styled.a`
  font-size:0.9rem;
  font-weight:300;
  text-decoration:none;
  letter-spacing:0.05rem;
  color: ${props => props.color};
  transition: all 200ms;
  &:hover {
    color : ${props => props.hover};
  }
  ${Breakpoints.tablet} {
    font-size:1rem;
  }
`

export const FooterLegal = styled.div`
  width:60%;
  display:flex;
  gap:1rem;
`

export const FooterLegalLink = styled.a`
  font-size:0.7rem;
  font-weight:300;
  opacity:0.9;
  text-decoration:none;
  color: ${props => props.color};
  transition: all 200ms;
  &:hover {
    color : ${props => props.hover};
  }
`