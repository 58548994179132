import styled from 'styled-components'
import { Display4, Body500} from "../../../typography"
import { Colors } from '../../../colours'
import { Cross, TightRose } from '../../../brand'
import { Fonts } from '../../../fonts'
import { Breakpoints } from '../../../breakpoints'

export const SocialsComponent = styled.div`
  margin:10vh 0;
  padding:10vh 0;
  ${Breakpoints.tablet} {
    margin:4vh 0;
    padding:4vh 0;
  }
`

export const SocialsHeader = styled.div`
  display:flex;
  justify-content: space-between;
  ${TightRose} {
    width: 4%;
    ${Breakpoints.mobile} {
      width:17%;
    }
    ${Breakpoints.medium} {
      width:17%;
    }
    ${Breakpoints.tablet} {
      width:10%;
    }
  }
  ${Breakpoints.mobile} {
    flex-wrap:wrap;
    justify-content: flex-start;
    gap:5vw;
  }
  ${Breakpoints.medium} {
    flex-wrap:wrap;
    justify-content: flex-start;
    gap:5vw;
  }
  ${Breakpoints.tablet} {
    flex-wrap:wrap;
    justify-content: flex-start;
    gap:3vw;
  }
`

export const SocialsTitle = styled(Display4)`
  color: ${Colors.orange};
  span {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${Colors.orange};
  }
  ${Breakpoints.mobile} {
    width: 100%;
    margin-bottom:2vh;
    span {
    -webkit-text-fill-color: ${Colors.light};
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: transparent;
  }
  }
  ${Breakpoints.medium} {
    width: 100%;
    margin-bottom:2vh;
  }
  ${Breakpoints.tablet} {
    width: 100%;
    font-size:10vw;
  }
`

export const SocialsText = styled(Body500)`
  color: ${Colors.grey};
  width: 50%;
  margin:1rem 0;
  ${Breakpoints.mobile} {
    width: 100%;
  }
  ${Breakpoints.medium} {
    width: 100%;
  }
  ${Breakpoints.tablet} {
    width: 75%;
    margin:1rem 0 0;
  }
`

export const SocialLinksComponent = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin:4rem 0 0;
  ${Breakpoints.mobile} {
    flex-direction:column;
    align-items: flex-start;
    justify-content: flex-start;
    ${Cross} {
      display: none;
    }
  }
  ${Breakpoints.medium} {
    flex-wrap:wrap;
    justify-content: flex-start;
    margin-left:-1.5rem;
  }
  ${Breakpoints.tablet} {
    margin: 2rem 0 0;
  }
`

export const SocialLink = styled.a`
  font-family: ${Fonts.display};
  color: ${Colors.light};
  font-size:4rem;
  font-weight:200;
  letter-spacing:0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 200ms;
  &:hover {
    color: ${Colors.grey};
  }
  ${Breakpoints.mobile} {
    font-size:25vw;
    font-weight:500;
    line-height:0.75;
  }
  ${Breakpoints.medium} {
    margin:0 1.5rem;
  }
`