// Library
import { Colors } from '../../../colours' 
// Styles
import { ContactHeaderComponent } from "./style"
// Components
import { WideRose, Swatches } from '../../../brand'
import { SocialLinks } from '../../../components/home/socials'


const ContactHeader = () => (
  <ContactHeaderComponent.Wrapper>
    <ContactHeaderComponent.Heading>
      <ContactHeaderComponent.Title>Let's talk</ContactHeaderComponent.Title>
      <WideRose fill={Colors.orange} className='svg'/>
    </ContactHeaderComponent.Heading>
    <ContactHeaderComponent.Email href='#'>infos@pseudo.design.com</ContactHeaderComponent.Email>
    <ContactHeaderComponent.Logo fill={Colors.light}/>
    <ContactHeaderComponent.Location>Vancouver, CANADA</ContactHeaderComponent.Location>
    <ContactHeaderComponent.Socials>
      <SocialLinks />
    </ContactHeaderComponent.Socials>
    <Swatches />
    <ContactHeaderComponent.Scroll>
      <ContactHeaderComponent.Arrow fill={Colors.light}/>
      <ContactHeaderComponent.ScrollText>Scroll down if you wish to contact us through the form</ContactHeaderComponent.ScrollText>
    </ContactHeaderComponent.Scroll>
  </ContactHeaderComponent.Wrapper>
)

export default ContactHeader