// Library
import { Colors } from '../../../colours'
// Styles
import styled from 'styled-components'
// Components
import { MainLogo, WideRose, HalfRose, TightRose } from '../../../brand'
import { Breakpoints } from '../../../breakpoints'

const GraphicsComponent = {
  Wrapper: styled.div`
    flex:1;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    gap:7vh;
    padding:5vh 4vw 10vh;
    /* background: darkgoldenrod; */
    ${Breakpoints.mobile} {display:none;}
    ${Breakpoints.medium} {display:none;}
    ${Breakpoints.tablet} {display:none;}
  `,
  Roses: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap:4vw;
    .graphic {width: 7vw;}
  `,
  Logo: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:0.5rem;
    .logo { width: 70%;}
    ${Breakpoints.smDesktop} {
      .logo { width: 90%; }
    }
    ${Breakpoints.tablet} {display:none;}
  `,
  Tagline: styled.div`
    width: 71%;
    display: flex;
    justify-content: space-between;
    ${Breakpoints.smDesktop} { width: 90%; } 
    p {
    font-size:0.85rem;
    text-transform: uppercase;
    color: ${Colors.light};
    font-weight:300;
    opacity:0.75;
    }
  `
}

const Graphics = () => {
  
  const tagline = 'fictive design for set production'
  const tagArray = Array.from(tagline)

  const spacedTagline = tagArray.map( letter => <p>{letter}</p> )

  
  
  return (
<GraphicsComponent.Wrapper>
  <GraphicsComponent.Roses>
    <TightRose fill={Colors.orange} className='graphic' />
    <HalfRose fill={Colors.grey} className='graphic'  />
    <WideRose fill={Colors.light} className='graphic' />
  </GraphicsComponent.Roses>
  <GraphicsComponent.Logo>
    <MainLogo fill={Colors.light} className='logo'/>
    <GraphicsComponent.Tagline>{spacedTagline}</GraphicsComponent.Tagline>
  </GraphicsComponent.Logo>
  <GraphicsComponent.Roses>
    <WideRose fill={Colors.light} className='graphic' />
    <HalfRose fill={Colors.grey} className='graphic'  />
    <TightRose fill={Colors.orange} className='graphic' />
  </GraphicsComponent.Roses>
</GraphicsComponent.Wrapper>
)}

export default Graphics