import { ReviewsComponent, ReviewItem } from "./style"
import data from './reviews.json'

const Reviews = () => {

  const ReviewList = Object.values(data)


  return (
    <ReviewsComponent.Wrapper>
    <ReviewsComponent.Heading>what directors / art directors have said about pseudo design</ReviewsComponent.Heading>
    <ReviewsComponent.Reviews>
    {  ReviewList.map(Review => 
        <ReviewItem.Wrapper>
          <ReviewItem.Text>{Review.text}</ReviewItem.Text>
          <ReviewItem.Author>{Review.author}</ReviewItem.Author>
          <ReviewItem.Caption>{Review.caption}</ReviewItem.Caption>
        </ReviewItem.Wrapper>
    )}
    </ReviewsComponent.Reviews>
    </ReviewsComponent.Wrapper>
  )
}

export default Reviews