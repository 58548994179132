import styled from "styled-components"
import { Body600, Body400, Display1 } from "../../../typography"
import { Colors } from "../../../colours"
import { Fonts } from "../../../fonts"
import { Breakpoints } from "../../../breakpoints"

export const HomeServicesComponents = styled.div`
  display:flex;
  gap:5%;
  margin:20vh 0;
  ${Breakpoints.mobile} {
    flex-direction:column;
    margin:10vh 0;
  }
  ${Breakpoints.medium} {
    flex-direction:column;
    margin:10vh 0;
  }
  ${Breakpoints.tablet} {
    flex-direction:column;
    margin:10vh 0;
  }
`

export const ServicesTitle = styled.div`
  flex:1.25;
  ${Body600} {
    color: ${Colors.grey};
    margin:10% 0;
  }
  ${Breakpoints.mobile},
  ${Breakpoints.medium} {
    flex:initial;
    gap:2vh;
  }
  ${Breakpoints.medium} {
    ${Body600} {
      margin:5% 0;
    }
    ${Display1} {
      font-size:16vw;
    }
  }
  ${Breakpoints.tablet} {
    ${Body600} {
      margin:5% 0;
    }
    ${Display1} {
      font-size:10vw;
    }
  }
`

export const ServicesDetails = styled.div`
  flex:2.5;
  display:flex;
  flex-direction:column;
  gap:2rem;
  ${Breakpoints.mobile} {
    flex:initial;
    gap:1.5rem;
  }
  ${Breakpoints.medium} {
    flex:initial;
    gap:1.5rem;
  }
  ${Breakpoints.tablet} {
    flex:initial;
    gap:1.5rem;
  }
`

export const ServiceInfo = styled.div`
  display:flex;
  flex-direction:column;
  gap:1.5rem;
  ${Breakpoints.mobile} {
    gap:0.75rem;
  }
  ${Breakpoints.medium} {
    gap:0.75rem;
  }
  ${Breakpoints.tablet} {
    gap:0.75rem;
  }
`

export const ServiceLogo = {
  Wrapper: styled.div`
    display:flex;
    align-items: flex-start;
    gap:2rem;
    width: 70%;
    .logo { flex:1.5;  }
    ${Breakpoints.mobile} {
      flex-direction:column;
      width: 90%;
      gap:0;
      .logo {
        width: 100%;
        flex:initital;
      }
    }
    ${Breakpoints.medium} {
      flex-direction:column;
      gap:0;
      .logo {
        width: 70%;
        flex:initital;
      }
    }
    ${Breakpoints.tablet} {
      width: 70%;
      .logo {
        width: 60%;
        flex:initial;
      }
    }
    `,
  Name : styled.p`
  flex:1;
  font-family:${Fonts.body};
  font-size:1.25rem;
  line-height:1.25;
  text-transform: uppercase;
  font-weight:300;
  letter-spacing: 0.5rem;
  ${Breakpoints.mobile},
  ${Breakpoints.medium} {
    flex:initial;
    padding-right:0;
  }
  ${Breakpoints.tablet} {
    padding-right:0;
    flex-grow:1;
  }
`
}

export const ServiceDescript = styled(Body400)`
  color: ${Colors.grey};
  width: 90%;
  ${Breakpoints.mobile},
  ${Breakpoints.medium} {
    width: 100%;
    color:${Colors.light};
  }
`