// Library
// Styles 
import { FormSectionComponent } from "./style";
// Components
import Form from "./form";
import Graphics from "./graphics";

const ContactForm = () => (
  <FormSectionComponent>
    <Form />
    <Graphics />
  </FormSectionComponent>
)

export default ContactForm