import { MobileFooterComponent } from "./style"

const MobileFooter = (props) =>  {
  
  const getYear = new Date().getFullYear()
  
  return (
  <MobileFooterComponent.Wrapper>
    <MobileFooterComponent.Socials>
      <MobileFooterComponent.SocialLink href='#' color={props.color}>IG</MobileFooterComponent.SocialLink>
      <MobileFooterComponent.SocialLink href='#' color={props.color}>Fb</MobileFooterComponent.SocialLink>
      <MobileFooterComponent.SocialLink href='#' color={props.color}>Be</MobileFooterComponent.SocialLink>
      <MobileFooterComponent.SocialLink href='#' color={props.color}>Drbl</MobileFooterComponent.SocialLink>
      <MobileFooterComponent.SocialLink href='#' color={props.color}>LI</MobileFooterComponent.SocialLink>
    </MobileFooterComponent.Socials>
    <MobileFooterComponent.Email href='#' color={props.color}>infos@pseudo.design.com</MobileFooterComponent.Email>
    <MobileFooterComponent.Mentions>
      <MobileFooterComponent.Legal>
        <MobileFooterComponent.LegalLink href='#' color={props.mobile}>Terms & Conditions</MobileFooterComponent.LegalLink>
        <MobileFooterComponent.LegalLink href='#' color={props.mobile}>Privacy policy</MobileFooterComponent.LegalLink>
        <MobileFooterComponent.LegalLink href='#' color={props.mobile}>Cookie policy</MobileFooterComponent.LegalLink>
      </MobileFooterComponent.Legal>
      <MobileFooterComponent.Copy color={props.mobile}>Copyright &copy; {getYear} Pseudo Design. All Rights Reserved.</MobileFooterComponent.Copy>
    </MobileFooterComponent.Mentions>
  </MobileFooterComponent.Wrapper>
)}

export default MobileFooter