import { ContactComponent } from "./style"
import { Colors } from "../../colours"
// Components
import Nav from '../../components/nav'
import ContactHeader from "../../components/contact/header"
import ContactForm from "../../components/contact/form"

const Contact = () => (
  <ContactComponent>
  <Nav color={Colors.light} hover={Colors.orange}/>
  <ContactHeader />
  <ContactForm />
  </ContactComponent>
)

export default Contact