import styled from 'styled-components'
import { Colors } from '../../colours'
import { Breakpoints, Pad } from '../../breakpoints'

export const ContactComponent = styled.section`
  width: 100vw;
  min-height:100vh;
  height:100%;
  overflow-x: hidden;
  background: ${Colors.black};
  color: ${Colors.light};
  ${Breakpoints.mobile} {padding: 10vh ${Pad.mobile} 0}
  ${Breakpoints.medium} {padding: 15vh ${Pad.medium} 0}
`