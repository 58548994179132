// Components
import { ProcessComponent, ProcessContent, ProcessHeading } from "./styled"
import ProcessSteps from "./steps"
import ProcessQuestions from "./questions"

const ServiceProcess = () => (
  <ProcessComponent>
    <ProcessHeading>the <span>pseudo design</span> process</ProcessHeading>
    <ProcessContent>
      <ProcessSteps />
      <ProcessQuestions />
    </ProcessContent>
  </ProcessComponent>

)

export default ServiceProcess