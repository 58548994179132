import { Colors } from '../../../colours'
// Styles
import { ServicesDetailsComponent } from './style'
import { ServiceComponent } from './style'
import { ServiceLogo } from '../../home/services/style'
import { Body400 } from '../../../typography'
// Components
import { PropsLogo, BrandingLogo, UILogo } from '../../../brand'
// Images
import Prop1 from '../../../img/services/pexels-nicole-berro-2393789.jpg'
import Prop2 from '../../../img/services/guillermo-casales-_m4AJ-xGGjA-unsplash.jpg'
import Brand1 from '../../../img/services/john-fornander-m2WpKnlLcEc-unsplash.jpg'
import Brand2 from '../../../img/services/mk-2-E6XStmd5wfk-unsplash.jpg'
import UI1 from '../../../img/services/pexels-mikhail-nilov-6963944.jpg'
import UI2 from '../../../img/services/thisisengineering-raeng-_PdoBI2XhkY-unsplash.jpg'

const Service = (props) => {
  const getLogo = () => {
    const logo = props.logo
    if( logo === 'props' ) {
      return <PropsLogo fill={Colors.orange} className='logo'/>
    } else if ( logo === 'branding') {
      return <BrandingLogo fill={Colors.orange} className='logo'/>
    } else if (logo === 'ui') {
      return <UILogo fill={Colors.orange} className='logo'/>
    }
  }

  return(
  <ServiceComponent.Wrapper>
    <ServiceComponent.Images>
      <ServiceComponent.Image1 src={props.img1} />
      <ServiceComponent.Image2 src={props.img2}/>
    </ServiceComponent.Images>
    <ServiceComponent.Content>
      <ServiceLogo.Wrapper>
        {getLogo()}
        <ServiceLogo.Name>{props.name}</ServiceLogo.Name>
      </ServiceLogo.Wrapper>
      <ServiceComponent.List>
        <ServiceComponent.ListItem>
          <Body400>A hendrerit quam morbi adipiscing ultricies suspendisse.</Body400>
        </ServiceComponent.ListItem>
        <ServiceComponent.ListItem>
          <Body400>A suscipit consequat commodo elit non nulla risus scelerisque.</Body400>
        </ServiceComponent.ListItem>
        <ServiceComponent.ListItem>
          <Body400>At vestibulum auctor tellus phasellus. Phasellus tellus ut vitae quis congue duis urna.</Body400>
        </ServiceComponent.ListItem>
        <ServiceComponent.ListItem>
          <Body400>In viverra egestas cursus sit amet vel cras massa.</Body400>
        </ServiceComponent.ListItem>
        <ServiceComponent.ListItem>
          <Body400>Pellentesque urna in arcu urna eget. Dui viverra eget velit egestas egestas facilisis.</Body400>
        </ServiceComponent.ListItem>
      </ServiceComponent.List>
    </ServiceComponent.Content>
  </ServiceComponent.Wrapper>
)}



const ServicesDetails = () => (
  <ServicesDetailsComponent.Wrapper>
    <Service logo='props' img1={Prop1} img2={Prop2} name='background props' />
    <Service logo='branding' img1={Brand1} img2={Brand2} name='branding'/>
    <Service logo='ui' img1={UI1} img2={UI2} name='UX/UI'/>
  </ServicesDetailsComponent.Wrapper>
)

export default ServicesDetails