import styled from 'styled-components'
import { Display3 } from '../../../typography'
import { Colors } from '../../../colours'
import { Arrow, MainLogo, Cross } from '../../../brand'
import { SocialLink, SocialLinksComponent } from '../../home/socials/style'
import { Breakpoints, Pad } from '../../../breakpoints'
import { Fonts } from '../../../fonts'

export const ContactHeaderComponent = {
  Wrapper: styled.div`
    width: 100%;
    padding: 3vh 0; 
    height:80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    ${Breakpoints.mobile} {
      height:90vh;
    }
  `,
  Heading: styled.div`
    display:flex;
    align-items: center;
    gap:2rem;
    .svg { width: 7.5%; }
    ${Breakpoints.mobile} {.svg {width: 25%;}}
    ${Breakpoints.medium} {.svg {width: 12%;}}
  `,
  Title: styled(Display3)`
    transition: all 300ms;
    color: ${Colors.orange};
    ${Breakpoints.mobile} {font-size:30vw;}
    ${Breakpoints.medium} {font-size:15vw;}
    ${Breakpoints.tablet} {font-size:15vw;}
  `,
  Email: styled.a`
    font-family:${Fonts.body};
    display: inline-block;
    font-size:2rem;
    font-weight:600;
    color:${Colors.light};
    text-decoration: none;
    transition: all 200ms;
    padding:3% 0 1%;
    &:hover {color:${Colors.orange}} 
    ${Breakpoints.mobile} {padding:3vh 0;}
    ${Breakpoints.tablet} {padding:5% 0 1%;}
  `,
  Logo: styled(MainLogo)`
    width: 36%;
    ${Breakpoints.mobile} {width: 90%;}
    ${Breakpoints.medium} {width: 60%;}
  `,
  Location: styled.p`
    font-family:${Fonts.body};
    font-size:1rem;
    font-weight:300;
    color: ${Colors.grey};
    line-height:0.75;
  `,
  Socials: styled.div`
    width: 70%;
    ${SocialLinksComponent} {
      margin:4% 0 4%;
      ${Breakpoints.tablet} {
        gap:0.5rem;
        ${Cross} {width:1.5rem; }
      }
    }
    ${Breakpoints.mobile} {
      background: ${Colors.grey};
      width: 100vw;
      margin:3vh 0 2vh -${Pad.mobile};
      padding: 0 ${Pad.mobile};
      ${SocialLinksComponent} {
        flex-direction:row;
        flex-wrap:wrap;
        gap:1rem;
      }
      ${SocialLink} {
        font-size:16vw;
        font-weight:300;
        &:hover {color:inherit;}
      }
    }
    ${Breakpoints.medium} {
      width: 90%; 
      ${SocialLinksComponent} {justify-content:space-between;}
      ${SocialLink} {margin:0;}
    }
    ${Breakpoints.tablet} {
      width: auto;
    }
  `,
  Scroll: styled.div`
    display:flex;
    align-items:center;
    height: 7vh;
    padding:3% 0 0;
    ${Breakpoints.mobile} {padding:0;}
  `,
  Arrow: styled(Arrow)`
    transform: scale(0.8) rotate(90deg) translate(15%,150%);
    transform-origin: top center;
  `,
  ScrollText: styled.p`
    font-family:${Fonts.body};
    font-size:0.9rem;
    color: ${Colors.light};
    font-weight:300;
    line-height:1;
    margin-left:-2rem;
  `
}