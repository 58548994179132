import styled from "styled-components"
import { Breakpoints, Pad } from "../../breakpoints"
import { Display3, Display5, Body500, Display1 } from "../../typography"
import { WideRose, Arrow } from "../../brand"
import { Link } from "react-router-dom"

export const BannerComponent = styled.div`
  height:70vh;
  width:100vw;
  margin-top: 20vh;
  margin-bottom:20vh;
  background: ${props => props.background};
  color: ${props => props.color};
  display:flex;
  gap:0;
  ${Breakpoints.mobile} {
    margin-top:8vh;
    margin-bottom:8vh;
    flex-direction:column;
    height:60vh;
    justify-content: space-between;
    margin-left:-${Pad.mobile};
    padding:${Pad.mobile};
  }
  ${Breakpoints.medium} {
    margin-top:8vh;
    margin-bottom:8vh;
    flex-direction:column;
    height:70vh;
    justify-content: space-between;
    margin-left:-${Pad.medium};
    padding: ${Pad.medium};
  }
  ${Breakpoints.tablet} {
    height:60vh;
    margin-top: 10vh;
    margin-bottom:10vh;
    flex-direction:column;
    margin-left:-${Pad.tablet};
    padding: ${Pad.tablet};
  }
  ${Breakpoints.smDesktop} {
    align-items:center;
    height:60vh;
    margin-left:-${Pad.smDesktop};
    padding: ${Pad.smDesktop};
  }
  ${Breakpoints.lgDesktop} {
    margin-left:-${Pad.lgDesktop};
    padding: ${Pad.lgDesktop};
  }
  ${Breakpoints.wide} {
    margin-left:-${Pad.wide};
    padding: ${Pad.wide};
  }
`

export const BannerLeft = styled.div`
  flex:2;
  ${Body500} {
    width:80%;
    margin:3rem 0 0;
    font-size: 1.1rem;
    width: 75%;
    ${Breakpoints.mobile} {
      width: 100%;
      margin:1.5rem 0 0;
      font-size:0.9rem;
    }
    ${Breakpoints.medium} {
      width: 100%;
      margin:0;
      flex-grow:1;
      display:flex;
      align-items: center;
    }
    ${Breakpoints.tablet} {
      width: 100%;
      margin:1.5rem 0 0;
    }
  }
  ${Breakpoints.mobile} {
    flex:1.25;
    display:flex;
    flex-direction:column;
    ${Display1} { font-size:28vw; }
  }
  ${Breakpoints.medium} {
    flex:1.5;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
  }
  ${Breakpoints.tablet} {
    flex:1;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    gap:0.5rem;
    ${Display1} { font-size:14vw; }
    ${Display3} {
      font-size:10vw;
      font-weight:400;
    }
  }
`

export const BannerRight = styled.div`
  flex:1;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  ${Breakpoints.mobile} {
    flex:1;
    gap:2rem;
  }
  ${Breakpoints.tablet} {
    flex:1;
    flex-direction: row;
  }
  ${Breakpoints.smDesktop} {
    height:100%;
    flex:1.25;
  }
`

export const BannerLink = styled(Link)`
  color: ${props => props.color};
  text-decoration: none;
  display:flex;
  align-items:center;
  gap:10px;
  transition: all 200ms;
  ${Breakpoints.mobile} {
    ${Display5} {
      font-size:14vw;
      font-weight:300;
    }
  }
  ${Breakpoints.tablet} {
    ${Display5} {
      font-size:8vw;
    }
  }
`

export const BannerBtn = styled.div`
  display:flex;
  align-items:center;
  gap:5%;
  ${WideRose} {
    width:60px;
    ${Breakpoints.mobile} {
      width: 50px;
    }}
  &:hover,
  &:active {
    ${BannerLink} { color: ${props => props.hover} }
    ${Arrow} { g { fill: ${props => props.hover} } }
    ${WideRose} { g { fill: ${props => props.hover} } }}
  ${Breakpoints.tablet} {
    &:nth-of-type(1) { flex: 1; }
    &:nth-of-type(2) { flex: 1.25; }
  }
`

