import styled from 'styled-components'
import { Breakpoints, Pad } from '../../../breakpoints'
import { Colors } from '../../../colours'
import { Body400 } from '../../../typography'
import { ServiceLogo } from '../../home/services/style'

export const ServicesDetailsComponent = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap:10vh;
    ${Breakpoints.mobile} { margin-left:0; }
    ${Breakpoints.medium} { margin-left:0; }
    ${Breakpoints.tablet} { margin-left:0; }
    ${Breakpoints.smDesktop} { margin-left:-${Pad.smDesktop}; }
    ${Breakpoints.lgDesktop} { margin-left:-${Pad.lgDesktop}; }
    ${Breakpoints.wide} { margin-left:-${Pad.wide}; }
  `
}

export const ServiceComponent = {
  Wrapper: styled.div`
    display:flex;
    gap:7vw;
    align-items: center;
    ${Breakpoints.mobile} {
      flex-direction: column;
      align-items:flex-start;
      gap:3vh;
    }
    ${Breakpoints.medium} {
      flex-direction: column;
      align-items:flex-start;
      gap:3vh;
    }
    ${Breakpoints.tablet} {
      flex-direction: column;
      align-items:flex-start;
      gap:3vh;
    }
    ${Breakpoints.smDesktop} {
      height:50vh;
      overflow: hidden;
    }
  `,
  Images: styled.div`
    flex:1;
    display:flex;
    gap:0.5rem;
    ${Breakpoints.mobile} {
      height:35vh;
      width: 100vw;
      margin-left:-${Pad.mobile};
      flex:initial;
      gap:1vw;
    }
    ${Breakpoints.tablet} {
      width: 80vw;
      flex:initial;
      height:30vh;
    }
    ${Breakpoints.smDesktop} {
      height:100%;
    }
  `,
  Image1: styled.img`
    flex:1.5;
    width: 100%;
    min-width:0;
  `,
  Image2: styled.img`
    flex:3;
    width: 100%;
    min-width:0;
    ${Breakpoints.mobile} { display:none; }
    ${Breakpoints.smDesktop} { display:none; }
  `,
  Content: styled.div`
    flex:1;
    display:flex;
    flex-direction: column;
    gap:2rem;
    .logo {
      flex:min-content;
      ${Breakpoints.mobile} {
        width: 90%;
      }
    }
    ${ServiceLogo.Wrapper} {
      width: 80%;
      gap:1rem;
      ${Breakpoints.mobile} {
        gap:0.025rem; 
        width: 100%;
      }
      ${Breakpoints.medium} { gap:0; }
      ${Breakpoints.tablet} { width: 60%; }
    }
    ${Breakpoints.mobile} {
      flex:initial;
    }
    ${Breakpoints.tablet} {
      flex:initial;
    }
    ${Breakpoints.smDesktop} {
      flex:1.5;
    }
  `,
  List: styled.ul`
    list-style:none;
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    ${Breakpoints.mobile} { gap: 0.5rem;}
  `,
  ListItem: styled.li`
    display:flex;
    align-items: center;
    gap:0.5rem;
    &::before {
      content:"";
      display: inline-block;
      width: 10px;
      height:10px;
      border-radius: 50%;
      border: solid 3px ${Colors.orange};
      margin-top:2px;
      ${Breakpoints.mobile} {
        width:7px;
        min-width:7px;
        height:7px;
        min-height:7px;
      }
    }
    ${Breakpoints.mobile} {
      ${Body400} { line-height:1.5;}
    }
  `
}