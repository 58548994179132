import styled from 'styled-components'
import { Body500, Body600, Display4 } from '../../../typography'
import { Colors } from '../../../colours'
import { Breakpoints } from '../../../breakpoints'

export const ReviewsComponent = {
  Wrapper: styled.div`
    margin:10vh 0;
    ${Breakpoints.mobile} {
      margin:5vh 0;
    }
  `,
    Heading: styled(Display4)`
    padding:15vh 0 5vh;
    width: 60%;
    ${Breakpoints.mobile} {
      width: 100%;
      padding:5vh 0;
    }
    ${Breakpoints.medium} {
      width: 100%;
      padding:7vh 0 5vh;
    }
    ${Breakpoints.tablet} {
      padding:7vh 0 5vh;
    }
  `,
  Reviews: styled.div`
    display:flex;
    flex-direction: column;
    flex-wrap:wrap;
    height:90vh;
    gap:2rem;
    margin:5vh 0;
    ${Breakpoints.mobile} {
      height:max-content;
    }
    ${Breakpoints.medium} {
      height:max-content;
    }
    ${Breakpoints.tablet} {
      height:110vh;
      gap:1rem;
    }
  `
}

export const ReviewItem = {
  Wrapper: styled.div`
    display:flex;
    flex-direction: column;
    width: 45%;
    color: ${Colors.black};
    ${Breakpoints.mobile} {
      width: 100%;
    }
    ${Breakpoints.medium} {
      width: 90%;
    }
  `,
  Text: styled(Body500)`
    white-space: break-spaces;
  `,
  Author: styled(Body600)`
    margin:0.5rem 0 0;
  `,
  Caption: styled(Body600)`
    &::before {
      content:'--';
      margin-right:0.5rem;
    }
  `
}