import styled from 'styled-components'
import { MainLogo, WideRose, HalfRose, VerticalLogo } from '../../../brand'
import { Colors } from '../../../colours'
import { Fonts } from '../../../fonts'
import { Breakpoints, Pad } from '../../../breakpoints'
import { Link } from 'react-router-dom'
import { LgButton } from '../../../buttons'

export const MobileNavComponent = {
  Wrapper: styled.nav`
    position:fixed;
    top:0;
    left:0;
    z-index:20;
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content:space-between;
    align-items:center;
    padding:0.5rem ${Pad};
    ${Breakpoints.tablet} {display:none }
    ${Breakpoints.smDesktop} {display:none }
    ${Breakpoints.lgDesktop} {display:none }
    ${Breakpoints.wide} {display:none }
  `,
  Icon: styled(WideRose)`
    height:45%;
    cursor: pointer;`
  ,
  Logo: styled(MainLogo)`
    height:40%;
  `
}

export const MobileOverlay = {
  Wrapper: styled.div`
    position:fixed;
    overflow:hidden;
    top:0;
    left:0;
    width: 100vw;
    height:100vh;
    z-index:30;
    background: ${Colors.light};
    transition: all 140ms;
    display:flex;
    flex-direction:column;
    &.closed { transform: translateX(105vw);}
    &.open { transform: translateX(0);}
    ${Breakpoints.mobile} { padding:0 ${Pad.mobile} 2vh;}
    ${Breakpoints.medium} { padding:0 ${Pad.medium} 2vh;}
  `,
  Nav: styled.div`
    width: 100%;
    height: 10vh;
    padding:0.5rem 0;
    display:flex;
    align-items:center;
    justify-content:flex-end;
  `,
  Icon: styled(HalfRose)`
    height: 45%;
    cursor: pointer;
  `,
  Logo: styled(VerticalLogo)`
    position:absolute;
    top:5vh;
    left:${Pad};
    width: 25%;
    ${Breakpoints.medium} {
      width:20%;
    }
  `,
  Menu: styled.div`
    display:flex;
    flex-direction:column;
    justify-content: flex-end;
    /* height: 65vh;  */
    flex-grow:1;
  `,
  MenuLink: styled(Link)`
    font-family: ${Fonts.display};
    font-size:30vw;
    font-weight:700;
    text-transform: uppercase;
    line-height:0.75;
    color: ${Colors.black};
    text-decoration:none;
    ${Breakpoints.medium} { font-size:20vw;}
    &:hover {color: ${Colors.orange};}
  `,
  Footer: styled.div`
    height:20vh;
    width: 100%;
    display:flex;
    align-items:center;
    ${Breakpoints.medium} {
      ${LgButton} {
        font-size:5rem;
        font-weight:400;
        width: 100%;
        &::after {
          bottom:-20px;
          left:0;
          height:20px;
        }
      }
    }
  `
}