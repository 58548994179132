import styled from 'styled-components'
import { Colors } from '../../colours'

export const ProjectComponent = styled.section`
  width: 100vw;
  min-height:100vh;
  height:100%;
  overflow-x: hidden;
  background: ${Colors.black};
  color: ${Colors.light};
`