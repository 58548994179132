import { FooterComponent, FooterLogo, FooterLinks , FooterTop, FooterEmail, FooterCopy, FooterBottom, FooterSocials, FooterSocialLink, FooterLegal, FooterLegalLink } from "./style"
import { StampLogo } from "../../../brand"

const DefaultFooter = (props) => {
  
  const getYear = new Date().getFullYear()  

  return (
  <FooterComponent  color={props.color}>
    <FooterLogo>
      <StampLogo fill={props.color}/>
    </FooterLogo>
    <FooterLinks>
      <FooterTop>
        <FooterEmail href='#' color={props.color} hover={props.hover}>infos@pseudo.design.com</FooterEmail>
        <FooterSocials>
          <FooterSocialLink href='#' color={props.color} hover={props.hover}>Instagram</FooterSocialLink>
          <FooterSocialLink href='#' color={props.color} hover={props.hover}>Facebook</FooterSocialLink>
          <FooterSocialLink href='#' color={props.color} hover={props.hover}>Behance</FooterSocialLink>
          <FooterSocialLink href='#' color={props.color} hover={props.hover}>Dribbble</FooterSocialLink>
          <FooterSocialLink href='#' color={props.color} hover={props.hover}>Linkedin</FooterSocialLink>
        </FooterSocials>
      </FooterTop>
      <FooterBottom>
        <FooterCopy>Copyright &copy; {getYear} Pseudo Design. All Rights Reserved.</FooterCopy>
        <FooterLegal>
          <FooterLegalLink href='#'  color={props.color} hover={props.hover}>Terms & conditions</FooterLegalLink>
          <FooterLegalLink href='#'  color={props.color} hover={props.hover}>Privacy policy</FooterLegalLink>
          <FooterLegalLink href='#'  color={props.color} hover={props.hover}>Cookie policy</FooterLegalLink>
        </FooterLegal>
      </FooterBottom>
    </FooterLinks>
  </FooterComponent>
)}

export default DefaultFooter