import styled from 'styled-components'
import { MainLogo } from '../../../brand'
import { Breakpoints } from '../../../breakpoints'
import { Body700, Display3 } from '../../../typography'

export const ServiceHeaderComponent = {
  Wrapper: styled.div`
    width: 100%;
    height:90vh;
    padding:0 0 10vh;
    display:flex;
    flex-direction:column;
    justify-content: center;
    gap:1rem;
    ${Breakpoints.mobile} {
      margin-top:12vh;
      gap:0.5rem;
    }
    ${Breakpoints.medium} {
      margin-top:12vh;
    }
    ${MainLogo} {
      width: 100%;
      ${Breakpoints.mobile} {
        width: 70%;
      }
      ${Breakpoints.medium} {
        width: 80%;
      }
    }
  `,
  Content: styled.div`
    display: flex;
    gap:7vw;
    ${Breakpoints.mobile} {
      flex-direction: column;
    }
    ${Breakpoints.medium} {
      flex-direction: column;
    }
    ${Breakpoints.tablet} {
      gap:2vw;
    }
  `,
  Heading: styled(Display3)`
    ${Breakpoints.mobile} {
      font-size:25vw;
      font-weight:700;
    }
    ${Breakpoints.medium} { font-size:20vw;}
  `,
  Text: styled.div`
    padding-top:10vh;
    ${Breakpoints.medium} {
      padding-top:4vh;
    }
    ${Body700} {
      column-count: 2;
      column-gap: 2rem;
      ${Breakpoints.mobile} { column-count: 1;}
      ${Breakpoints.medium} { column-count: 1;}
      ${Breakpoints.tablet} { column-count: 1;}
    }
  `
}