// Library
import { Colors } from "../../colours"
// Styles
import { ProjectComponent } from "./style"
// Components
import Nav from '../../components/nav'
import ProjectHeader from "../../components/project-page/header"
import ProjectLayout from "../../components/project-page/layout"
import Footer from '../../components/footer'

const Project = () => (
  <ProjectComponent>
    <Nav color={Colors.light} hover={Colors.orange}/>
    <ProjectHeader />
    <ProjectLayout />
    <Footer color={Colors.orange} hover={Colors.light} mobile={Colors.grey} />
  </ProjectComponent>
)

export default Project