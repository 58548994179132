import styled from 'styled-components'
import { Breakpoints } from '../../../breakpoints'
import { Colors } from '../../../colours'
import { Body400, Display5 } from '../../../typography'

const Steps = styled.div` flex:1;`

const Step = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap:1rem;
    padding: 3rem 0;
    border-bottom: solid 1px ${Colors.black};
    &:first-of-type { border-top:solid 1px ${Colors.black}; }
    ${Breakpoints.mobile} { &:last-of-type { border-bottom:none;}}
    ${Breakpoints.medium} { &:last-of-type { border-bottom:none;}}
    `,
  Heading: styled(Display5)`
    ${Breakpoints.tablet} {
      font-size:8vw;
      font-weight:400;
    }
  `,
  Text: styled(Body400)``
}

const ProcessSteps = () => (
  <Steps>
    <Step.Wrapper>
      <Step.Heading>1. get a quote</Step.Heading>
      <Step.Text>
      Amet dolor fermentum, mauris, neque, vel dolor. Nibh in sed sapien, habitant. Euismod mauris amet nibh vitae, nulla purus lectus etiam. Sodales quam tincidunt mauris sagittis. Felis non tincidunt donec ultrices lacus. Massa viverra vivamus malesuada a, viverra pharetra. At mollis mattis viverra lacus cursus morbi. Luctus sagittis vel volutpat aliquam. Lectus porta mi pellentesque urna.  
      </Step.Text>
    </Step.Wrapper>
    <Step.Wrapper>
      <Step.Heading>2. Brief & brainstorm</Step.Heading>
      <Step.Text>
      Amet dolor fermentum, mauris, neque, vel dolor. Nibh in sed sapien, habitant. Euismod mauris amet nibh vitae, nulla purus lectus etiam. Sodales quam tincidunt mauris sagittis. Felis non tincidunt donec ultrices lacus. Massa viverra vivamus malesuada a, viverra pharetra. At mollis mattis viverra lacus cursus morbi. Luctus sagittis vel volutpat aliquam. Lectus porta mi pellentesque urna.  
      </Step.Text>
    </Step.Wrapper>
    <Step.Wrapper>
      <Step.Heading>3. working together</Step.Heading>
      <Step.Text>
      Amet dolor fermentum, mauris, neque, vel dolor. Nibh in sed sapien, habitant. Euismod mauris amet nibh vitae, nulla purus lectus etiam. Sodales quam tincidunt mauris sagittis. Felis non tincidunt donec ultrices lacus. Massa viverra vivamus malesuada a, viverra pharetra. At mollis mattis viverra lacus cursus morbi. Luctus sagittis vel volutpat aliquam. Lectus porta mi pellentesque urna.  
      </Step.Text>
    </Step.Wrapper>
    <Step.Wrapper>
      <Step.Heading>4. final touches</Step.Heading>
      <Step.Text>
      Amet dolor fermentum, mauris, neque, vel dolor. Nibh in sed sapien, habitant. Euismod mauris amet nibh vitae, nulla purus lectus etiam. Sodales quam tincidunt mauris sagittis. Felis non tincidunt donec ultrices lacus. Massa viverra vivamus malesuada a, viverra pharetra. At mollis mattis viverra lacus cursus morbi. Luctus sagittis vel volutpat aliquam. Lectus porta mi pellentesque urna.  
      </Step.Text>
    </Step.Wrapper>
  </Steps>
)

export default ProcessSteps