// Library
import { Colors } from '../../../colours'
import { Breakpoints, Pad } from '../../../breakpoints'
// Styles
import styled from 'styled-components'
import { Body300, Body500 } from '../../../typography'
// Components


export const ProjectImageComponent = {
  Wrapper: styled.div`
    width: ${props => props.width};
    display:flex;
    flex-direction:column;
    gap:1rem;
    ${Breakpoints.mobile} {
      width: 100%;
      gap:0.5rem;
    }
    ${Breakpoints.medium} {
      width: 100%;
      gap:0.5rem;
    }
    ${Breakpoints.tablet} { width: ${props => props.tablet};}
    ${Breakpoints.smDesktop} { width: ${props => props.tablet};}
  `,
  Image: styled.img`
    width: 100%;
    height: auto;
    ${Breakpoints.mobile} {
      width: 100vw;
      margin-left: -${Pad.mobile};
    }
  `,
  Caption: styled(Body300)`
    color: ${Colors.grey};
  `
}

export const ProjectGalleryComponent = {
  Wrapper: styled.div`
    display:flex;
    flex-wrap:wrap;
    gap:1%;
    width: 100%;
    margin: 10vh 0;
    ${Breakpoints.mobile} {
      flex-direction:column;
      gap:0.5rem;
      margin:4vh 0 0;
    }
    ${Breakpoints.medium} {
      flex-direction:column;
      gap:0.5rem;
      margin:4vh 0 0;
    }
    ${Breakpoints.tablet} {flex-wrap:wrap;}
    ${Breakpoints.smDesktop} {flex-wrap:wrap;}
  `,
  Image: styled.img`
    height:60vh;
    &:first-of-type {
      width:30%;
      ${Breakpoints.mobile} {
        width: 100vw;
        margin-left: -${Pad.mobile};        
        height: 40vh;
      }
      ${Breakpoints.medium} {
        width: 100%;
      }
    }
    &:last-of-type { 
      width:69%; 
      ${Breakpoints.mobile} {
        width: 100vw;
        margin-left: -${Pad.mobile};        
        height: 30vh;
      }
      ${Breakpoints.medium} {
        width: 100%;
      }
    }
    ${Breakpoints.tablet} {height: 40vh; }
  `
}

export const ProjectParagraph = styled(Body500)`
  color: ${props => props.color};
  margin:2rem 0;
  width: 85%;
  br {
   display: block;
   margin: 0.5rem 0;
  }
  ${Breakpoints.mobile} { width: 100%;}
  ${Breakpoints.medium} { width: 100%;}
  ${Breakpoints.tablet} { width: 100%;}
  `

export const ProjectMasonryComponent = {
  Wrapper: styled.div`
    height: 120vh;
    margin:0 0 10vh;
    display:grid;
    grid-template-columns: repeat(3,33%);
    grid-template-rows: 50% 50%;
    align-content: center;
    grid-gap:1rem;
    ${Breakpoints.mobile} {
      height: max-content;
      display:flex;
      flex-direction:column;
      gap:0.5rem;
      margin:4vh 0;
    }
    ${Breakpoints.medium} {
      height: max-content;
      display:flex;
      flex-direction:column;
      gap:0.5rem;
      margin:4vh 0;
    }
    ${Breakpoints.tablet} {
      width: 100vw;
      margin-left:-${Pad.tablet};
      gap:0.25rem;
      height: 100vh;
    }
    ${Breakpoints.smDesktop} {
      margin-left:-${Pad.smDesktop};
      gap:0.5rem;
    }
    img {
      width: 100%;
      height: auto;
      ${Breakpoints.mobile} {
        width: 100vw !important;
        margin: 0 0 0 -${Pad.mobile} !important;
        height: auto !important;
      }
      ${Breakpoints.medium} {
        width: 100vw !important;
        margin: 0 0 0 -${Pad.medium} !important;
        height: auto !important;
      }
    }
    img:nth-of-type(1) { height:80%; align-self: end;  }
    img:nth-of-type(2) { height: 98%; align-self: end;}
    img:nth-of-type(3) { margin-top:13%; align-self: end; grid-column: span 1}
    img:nth-of-type(4) { height: 100%; }
    img:nth-of-type(5) { grid-column:2 / 4; height: 90%; }
  `
}

export const ProjectLayoutComponent = {
  Wrapper: styled.div`
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    gap:3vw;
    padding:0 0 20vh;
  `
}

