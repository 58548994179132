// Library
import { Colors } from "../../../colours";
// Styles
import { ProjectImageComponent, ProjectGalleryComponent, ProjectMasonryComponent, ProjectLayoutComponent, ProjectParagraph } from "./style";
// Components
// Images
import img1 from '../../../img/project/img1.jpg'
import img2 from '../../../img/project/img2.jpg'
import img3 from '../../../img/project/img3.jpg'
import img4 from '../../../img/project/img4.jpg'
import img5 from '../../../img/project/img5.jpg'
import Masontry1 from '../../../img/project/masonry1.jpg'
import Masontry2 from '../../../img/project/masonry2.jpg'
import Masontry3 from '../../../img/project/masonry3.jpg'
import Masontry4 from '../../../img/project/masonry4.jpg'
import Masontry5 from '../../../img/project/masonry5.jpg'

const altTags = 'pseudo-design image work dsign'

const ProjectImage = (props) => (
  <ProjectImageComponent.Wrapper width={props.width} tablet={props.tablet}>
    <ProjectImageComponent.Image src={props.img} alt={altTags}/>
    <ProjectImageComponent.Caption>{props.caption}</ProjectImageComponent.Caption>
  </ProjectImageComponent.Wrapper>
)

const ProjectGallery = (props) => {
  const imgArray = props.imgs

  return ( <ProjectGalleryComponent.Wrapper>{
    imgArray.map ( image => <ProjectGalleryComponent.Image src={image} alt={altTags} /> )}
    <ProjectParagraph color={Colors.grey}>Justo, volutpat eros, faucibus sit nunc leo. Cursus quam quis cras quam. Aliquam mauris dictum laoreet tincidunt magna auctor viverra odio. Posuere ac viverra eget aliquet facilisis vitae, diam tempor accumsan. Blandit aliquam eget morbi aliquet. Ullamcorper felis metus amet enim. Ornare massa neque tempus arcu gravida at. Maecenas ipsum nunc sem lacus, id gravida. In nisl pellentesque leo, magna massa aliquam, nibh vulputate sed. Consequat nulla orci aenean tellus. Et facilisis amet vitae ut. In magna purus libero nisi. Elementum molestie neque non ac eu facilisis enim aliquet nunc.</ProjectParagraph>
    </ProjectGalleryComponent.Wrapper> )
}


const ProjectMasonry = () =>  (
    <ProjectMasonryComponent.Wrapper>
      <img src={Masontry1} alt={altTags}/>
      <img src={Masontry2} alt={altTags}/>
      <img src={Masontry3} alt={altTags}/>
      <img src={Masontry4} alt={altTags}/>
      <img src={Masontry5} alt={altTags}/>
    </ProjectMasonryComponent.Wrapper>
  )


const ProjectLayout = () => (
  <ProjectLayoutComponent.Wrapper>
    <ProjectImage width='60%' tablet='100%' img={img1} caption='Justo, volutpat eros, faucibus sit nunc leo. Cursus quam quis cras quam. Aliquam mauris dictum laoreet tincidunt magna auctor viverra odio. Posuere ac viverra eget aliquet facilisis vitae.'/>
    <ProjectImage width='50%' tablet='52%' img={img2} caption='Justo, volutpat eros, faucibus nuncleo. Cursus quam quis cras quam. Aliquam mauris dictum laoreet tincidunt magna auctor viverra'/>
    <ProjectImage width='42%' tablet='44%' img={img3} caption='Justo, volutpat eros, faucibus nuncleo. Cursus quam quis cras quam mauris dictum laoreet'/>
    <ProjectGallery imgs={[img4, img5]}/>
    <ProjectMasonry />
    <ProjectParagraph color={Colors.light}>
    Cursus ut velit lacinia varius. Scelerisque ante laoreet vestibulum mauris. Et euismod egestas habitant est. Leo ut pellentesque mauris tortor. Semper dolor, mi eu varius eget ipsum commodo vulputate accumsan. Dui, pellentesque enim dui turpis lacus in faucibus. Eros, pretium consectetur at nulla eget facilisi quis. Diam lobortis porta rhoncus a dolor, nulla nunc. Habitant mollis viverra blandit quam sed ac. Est adipiscing vel facilisis id urna auctor volutpat et. Molestie risus, vitae aenean eget sodales consectetur.<br />A tincidunt nunc commodo rhoncus phasellus habitasse fringilla ut quis. Mus mauris, erat vulputate in. Sed risus mi tortor amet. Neque, nec, posuere lacus morbi bibendum congue imperdiet lacus. Orci, urna leo vitae praesent sed a non faucibus mi. Pulvinar tristique amet, ultricies sit ornare justo faucibus amet ultrices.
    </ProjectParagraph>
  </ProjectLayoutComponent.Wrapper>
)

export default ProjectLayout