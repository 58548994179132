import { ServicesComponent } from "./style"
import { Colors } from "../../colours"
// Components
import Nav from '../../components/nav'
import ServiceHeader from "../../components/services/header"
import ServicesDetails from "../../components/services/services"
import ServiceProcess from "../../components/services/process"
import Banner from '../../components/banner'
import Footer from '../../components/footer'

const Services = () => (
  <ServicesComponent>
  <Nav color={Colors.black} hover={Colors.orange}/>
  <ServiceHeader />
  <ServicesDetails />
  <ServiceProcess />
  <Banner background={Colors.orange} color={Colors.black} hover={Colors.light} />
  <Footer color={Colors.black} hover={Colors.orange} mobile={Colors.grey}/>
  </ServicesComponent>
)

export default Services