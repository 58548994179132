// Routes
import { Routes, Route } from "react-router-dom";
// Pages
import Home from './pages/home'
import Work from './pages/work'
import Services from './pages/services'
import Project from './pages/project'
import Contact from './pages/contact'

const Pages = () => (
  <Routes>
  <Route path='/' element= { <Home /> }/>
  <Route path='/work' element= { <Work /> } />
  <Route path='/services' element= { <Services /> } />
  <Route path='/project' element= { <Project /> } />
  <Route path='/contact' element= { <Contact /> } />
  </Routes>
)

export default Pages