export const Breakpoints = {
  mobile: "@media only screen and (max-width: 600px)",
  medium: "@media only screen and (min-width:600px) and (max-width: 800px)",
  tablet: "@media only screen and (min-width:800px) and (max-width: 1200px)",
  smDesktop: "@media only screen and (min-width:1200px) and (max-width: 1500px)",
  lgDesktop: "@media only screen and (min-width:1500px) and (max-width: 1920px)",
  wide: "@media only screen and (min-width:1920px)"
}

export const Pad =  {

  mobile:"5vw",
  medium:"4vw",
  tablet:"4vw",
  smDesktop:"6vw",
  lgDesktop:"8vw",
  wide:"8vw"

}
