import styled from 'styled-components'
import { Breakpoints } from '../../../breakpoints'
import { Colors } from '../../../colours'
import { Fonts } from '../../../fonts'
import { Body400 } from '../../../typography'

const Questions = styled.div`
    flex:1;
    ${Breakpoints.tablet} {flex:1.25}
  `

const Question = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap:1rem;
    padding: 2rem 0;
    border-bottom: solid 1px ${Colors.black};
    &:first-of-type { border-top:solid 1px ${Colors.black}; }
    `,
  Heading: styled.h3`
    font-size:5vw;
    font-weight:500;
    font-family:${Fonts.display};
    color: ${Colors.orange};
    ${Breakpoints.mobile} { font-size:12vw;}
    ${Breakpoints.medium} { font-size:10vw;}
    ${Breakpoints.tablet} { font-size:8vw;}
  `,
  Text: styled(Body400)`` 
}

const ProcessQuestions = () => (
  <Questions>
    <Question.Wrapper>
      <Question.Heading>How much is it?</Question.Heading>
        <Question.Text>
        Nulla gravida ut adipiscing vel. Scelerisque urna dui quis nec cum amet. Viverra scelerisque in ipsum tristique. Vitae, rutrum dignissim dignissim nec aliquam amet scelerisque diam. Pellentesque dignissim sagittis nec risus curabitur gravida ut sed. Scelerisque est parturient a a tellus proin pellentesque. Sem at in egestas viverra a. Morbi aliquam amet vitae tincidunt morbi et ut.
        </Question.Text>
    </Question.Wrapper>
    <Question.Wrapper>
      <Question.Heading>When do I pay?</Question.Heading>
        <Question.Text>
        Etiam lacinia eu, odio aliquam lectus cursus a, lacus, laoreet. Pharetra amet, est tortor mattis sit viverra ultricies euismod in. Nibh eu ac ac gravida blandit augue amet senectus. Risus velit non magna eget suspendisse arcu nunc odio. Orci scelerisque praesent egestas mauris.
        </Question.Text>
    </Question.Wrapper>
    <Question.Wrapper>
      <Question.Heading>How long for some designs?</Question.Heading>
        <Question.Text>
        Etiam lacinia eu, odio aliquam lectus cursus a, lacus, laoreet. Pharetra amet, est tortor mattis sit viverra ultricies euismod in. Nibh eu ac ac gravida blandit augue amet senectus. Risus velit non magna eget suspendisse arcu nunc odio. Orci scelerisque praesent egestas mauris. Ultrices sed sed fermentum vitae in magna. Dictum odio sem pellentesque pretium iaculis.<br />Nulla gravida ut adipiscing vel. Scelerisque urna dui quis nec cum amet. Viverra scelerisque in ipsum tristique. Vitae, rutrum dignissim dignissim nec aliquam amet scelerisque diam. Pellentesque dignissim sagittis nec risus curabitur gravida ut sed. Scelerisque est parturient a a tellus proin pellentesque. Sem at in egestas viverra a. Morbi aliquam amet vitae tincidunt morbi et ut.
        </Question.Text>
    </Question.Wrapper>
    <Question.Wrapper>
      <Question.Heading>How do you work with art departments & production studios?</Question.Heading>
        <Question.Text>
        Etiam lacinia eu, odio aliquam lectus cursus a, lacus, laoreet. Pharetra amet, est tortor mattis sit viverra ultricies euismod in. Nibh eu ac ac gravida blandit augue amet senectus. Risus velit non magna eget suspendisse arcu nunc odio. Orci scelerisque praesent egestas mauris.<br />Vitae, rutrum dignissim dignissim nec aliquam amet scelerisque diam. Pellentesque dignissim sagittis nec risus curabitur gravida ut sed. Scelerisque est parturient a a tellus proin pellentesque. Sem at in egestas viverra a. Morbi aliquam amet vitae tincidunt morbi et ut.
        </Question.Text>
    </Question.Wrapper>
  </Questions>

)

export default ProcessQuestions