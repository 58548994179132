import styled from 'styled-components'
import { Breakpoints } from '../../../breakpoints'
import { Fonts } from '../../../fonts'

export const FormSectionComponent = styled.div`
  width: 100%;
  height: 100vh;
  margin-top:3vh;
  display:flex;
  align-items:center;
  justify-content: space-between;
  gap:5vw;
  font-family:${Fonts.body};

  ${Breakpoints.mobile} {padding:0;}
  ${Breakpoints.medium} {padding:0;}
  ${Breakpoints.tablet} {
    flex-direction:column;
    gap:0;
    padding:0 0 5vh;
  }
  ${Breakpoints.smDesktop} { padding:0;}
`
