import { Colors } from "../../colours"
// Components
import { WorkComponent } from "./style"
import Nav from '../../components/nav'
import FeaturedProjects from "../../components/work/projects"
import Reviews from "../../components/work/reviews"
import Banner from "../../components/banner"
import Footer from "../../components/footer"

const Work = () =>  (
  <WorkComponent.Wrapper>
  <Nav color={Colors.black} hover={Colors.light}/>
  <WorkComponent.Heading>Featured Projects</WorkComponent.Heading>
  <FeaturedProjects />
  <Reviews />
  <Banner background={Colors.black} color={Colors.light} hover={Colors.orange} />
  <Footer color={Colors.black} hover={Colors.light} mobile={Colors.light}/>
  </WorkComponent.Wrapper>
)

export default Work