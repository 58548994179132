import { HeaderComponent, FirstCol, LastCol,BackgroundImg, Buttons, Tag } from "./style"
import { Display1 } from "../../../typography"
import { Swatches, HalfRose, TightRose, WideRose, Cross } from '../../../brand'
import { Graphics } from "./style"
import { Colors } from "../../../colours"
import { LgButton, MdButton } from "../../../buttons"
import HeaderImg from '../../../img/home/header.jpg'

const HomeHeader = () => (
  <HeaderComponent>
    <FirstCol>
      <BackgroundImg src={HeaderImg}/>
      <Display1>create you characters <br/>& build your world,<br/>let me decorate it</Display1>
    </FirstCol>
    <LastCol>
      <Swatches />
      <Graphics>
        <HalfRose fill={Colors.orange}/>
        <TightRose fill={Colors.orange}/>
        <WideRose fill={Colors.orange}/>
        <Cross className='cross top-left' fill={Colors.light}/>
        <Cross className='cross top-right' fill={Colors.light}/>
        <Cross className='cross bottom-left' fill={Colors.light}/>
        <Cross className='cross bottom-right' fill={Colors.light}/>
      </Graphics>
      <Tag>Fictitious graphic design to populate your set, from imaginary brands and logos, to posters and billboards, or screens and interfaces</Tag>
      <Buttons>
        <LgButton color={Colors.orange} hover={Colors.light} to='/contact'>Get quote</LgButton>
        <MdButton color={Colors.light} hover={Colors.grey} to='/work'>Browse work</MdButton>
        <MdButton color={Colors.light} hover={Colors.grey} to='/services' >Read about our services</MdButton>
      </Buttons>
      {/* <Intro>Proin pharetra ullamcorper netus felis turpis eu interdum adipiscing dui. A cursus id dignissim adipiscing adipiscing adipiscing. Eu mi lacinia in placerat malesuada libero, ac. Enim nec et aliquam vulputate aliquet tristique. Arcu pellentesque neque nisl, est sit eu elementum pellentesque. </Intro> */}
    </LastCol>
  </HeaderComponent>
)

export default HomeHeader