import styled from "styled-components"
import { Link } from "react-router-dom"
import { Fonts } from "./fonts"
import { Breakpoints } from "./breakpoints"

export const Button = styled(Link)`
  font-family: ${Fonts.display};
  text-decoration:none;
  color:${props => props.color};
  border-bottom: solid ${props => props.color};
  position:relative;
  text-transform: uppercase;
  transition: all 200ms;
  &:hover {
    color: ${props => props.hover};
    border-color: ${props => props.hover};
    &::after {
      background :${props => props.hover};
    }
  }
  &::after {
    content:"";
    display:inline-block;
    position:absolute;
    height: 10px;
    bottom:-10px;
    left:50%;
    background: ${props => props.color};
    transition: all 200ms;
    ${Breakpoints.mobile} {
      left:0;
    }
  }
`

export const MdButton = styled(Button)`
  font-weight:300;
  font-size:2.5rem;
  border-width:1px;
  letter-spacing:0.1rem;
  &::after {
    width: 1px;
  }
  ${Breakpoints.mobile} {
    font-size:3rem;
  }
`

export const LgButton = styled(Button)`
  font-weight:600;
  font-size:3rem;
  border-width:2px;
  letter-spacing:0.05rem;
  &::after {
    width:2px;
  }
  ${Breakpoints.mobile} {
    font-size:4rem;
  }
`

