// Projects List
import ProjectsArray from './projects.json'
// Components
import { Tag } from "../../../components/tags"
import { Arrow } from '../../../brand'
// Styles
import { Colors } from '../../../colours'
import { Display5, Heading6 } from "../../../typography"
import { Projects } from './style'

const FeaturedProjects = () => {

  const ProjectsList = Object.values(ProjectsArray)

  return (
    <Projects.Wrapper>
    {/* -------------------- */}
    {   ProjectsList.map( Project => 
    <Projects.Project>
      <Projects.Main>
        <Projects.Link to='/project'>
          <Display5>{Project.name}</Display5>
          <Arrow fill={Colors.light} />
        </Projects.Link>
        <Projects.Descript>{Project.text}</Projects.Descript>
      </Projects.Main>
      <Projects.Infos>
        <Projects.Details>
          <Heading6>{Project.client}</Heading6>
          <Heading6>/</Heading6>
          <Heading6>{Project.year}</Heading6>
        </Projects.Details>
        <Projects.Tags>
          { Object.values(Project.tags).map(tag => <Tag size='1' color={Colors.black} content={tag} /> )}
        </Projects.Tags>
      </Projects.Infos>
    </Projects.Project> )}
  {/* -------------------- */}
  </Projects.Wrapper>
  )

}

export default FeaturedProjects