// Default Nav
import DefaultNav from "./default";
// Mobile Nav
import MobileNav from './mobile-nav';

const Nav = (props) => (
  <>
    <MobileNav color={props.color} hover={props.hover} />
    <DefaultNav color={props.color} hover={props.hover} />
  </>

)

export default Nav