// Library
import { Colors } from '../../../colours'
import { Breakpoints } from '../../../breakpoints'
// Styles
import styled from 'styled-components'
import { Body700, Display1 } from '../../../typography'
// Components


export const ProjectHeaderComponent = {
  Wrapper: styled.div`
    padding: 15vh 0 10vh;
    display:flex;
    flex-direction: column;
    gap:10vh;
    position:relative;
    ${Breakpoints.mobile} { gap:5vh;}
    ${Breakpoints.medium} { gap:5vh;}
    ${Breakpoints.tablet} { padding:7vh 0;}
    ${Breakpoints.smDesktop} {
      height: 80vh;
      padding:7vh 0 ;
    }
  `,
  Heading: styled.div`
    display:flex;
  ${Breakpoints.mobile} {flex-direction: column;}
  ${Breakpoints.medium} {flex-direction: column;}
  ${Breakpoints.tablet} {flex-direction: column;}
  ${Breakpoints.smDesktop} {flex-direction: column; flex-wrap:wrap; gap:2vh;}
  `,
  Title: styled(Display1)`
    color: ${Colors.light};
    flex:1.5;
    ${Breakpoints.mobile} {
      font-size:22vw;
      margin:0 0 3vh; 
    }
    ${Breakpoints.medium} { margin: 0 0 5vh; }
    ${Breakpoints.tablet} { margin: 0 0 5vh; }
    ${Breakpoints.smDesktop} { width: 100%; }
  `,
  Tags: styled.div`
    flex:1;
    display:flex;
    align-items: center;
    justify-content: center;
    gap:4vw;
    padding:0 5vw;
    .tag {width: 50%;}
    ${Breakpoints.mobile} {
      flex-direction: column;
      align-items:flex-start;
      padding:0;
      gap:0;
      .tag {
        &:first-of-type {width: 60%}
        &:last-of-type {width: 51%;}
      }
    }
    ${Breakpoints.medium} {
      justify-content: flex-start;
      padding:0;
      .tag {width: 35%;}
    }
    ${Breakpoints.tablet} {
      justify-content: flex-start;
      padding:0;
      .tag {width: 28%;}
    }
    ${Breakpoints.smDesktop} {
      flex-direction:column;
      align-items:flex-start;
      padding: 0;
      gap:0;
      .tag {
      &:first-of-type{width:23%;}
      &:last-of-type{width:20%;}
    }
    }
  ` ,
  Paragraph: styled(Body700)`
    column-count: 2;
    column-gap: 2rem;
    ${Breakpoints.mobile} {column-count:1;}
    ${Breakpoints.medium} {column-count:1;}
    ${Breakpoints.smDesktop} {
      width: 60%;
      position: absolute;
      right:0;
      bottom:20vh;
    }
  `
}