import styled from "styled-components"
import HeroImg from '../../../img/home/full-width.jpg'
import { Breakpoints, Pad } from "../../../breakpoints"

const HeroComponent = styled.img`
  width: 100vw;
  height:80vh;
  ${Breakpoints.mobile} {
    height:50vh;
    margin-left:-${Pad.mobile};
  }
  ${Breakpoints.medium} { margin-left:-${Pad.medium}; }
  ${Breakpoints.tablet} { margin-left:-${Pad.tablet}; }
  ${Breakpoints.smDesktop} { margin-left:-${Pad.smDesktop}; }
  ${Breakpoints.lgDesktop} { margin-left:-${Pad.lgDesktop}; }
  ${Breakpoints.wide} { margin-left:-${Pad.wide}; }
`

const Hero = () => (
  <HeroComponent src={HeroImg} />
)

export default Hero