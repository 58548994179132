import styled from 'styled-components'
import { Cross } from '../../brand'

export const TagComponent = {
  Wrapper: styled.div`
    display:flex;
    flex-wrap:nowrap;
    align-items:center;
    gap:0.5%;
  `,
  Name: styled.p`
    text-transform: uppercase;
    color: ${props => props.color};
    font-size:calc(${props => props.size} * 0.85rem);
    letter-spacing:calc((${props => props.size} * 0.85rem) / 4);
    font-weight:500;
    white-space: nowrap;
  `,
  Cross: styled(Cross)`
    height:calc(${props => props.size} * 0.75rem);
    g {
      stroke-width: 2;
    }
  `
} 

export const Tag = (props) => (
  <TagComponent.Wrapper>
    <TagComponent.Cross fill={props.color} size='1'/>
    <TagComponent.Name size={props.size} color={props.color}>{props.content}</TagComponent.Name>
  </TagComponent.Wrapper>
)

export const PropTag = (props) => (
  <TagComponent.Wrapper>
  <TagComponent.Cross fill={props.color} size='1'/>
  <TagComponent.Name size={props.size} color={props.color}>background props</TagComponent.Name>
</TagComponent.Wrapper>
)

export const BrandingTag = (props) => (
  <TagComponent.Wrapper>
  <TagComponent.Cross fill={props.color} size='1'/>
  <TagComponent.Name size={props.size} color={props.color}>Branding</TagComponent.Name>
</TagComponent.Wrapper>
)

export const UITag = (props) => (
  <TagComponent.Wrapper>
  <TagComponent.Cross fill={props.color} size='1'/>
  <TagComponent.Name size={props.size} color={props.color}>UX / UI</TagComponent.Name>
</TagComponent.Wrapper>
)