import styled from "styled-components"
import { Breakpoints, Pad } from "../../../breakpoints"
import { Fonts } from "../../../fonts"
import { Colors } from "../../../colours"

export const GalleryComponent = styled.div`
  width: 100%;
  height:120vh;
  position: relative;
  ${Breakpoints.mobile} {
    height: max-content;
  }
  ${Breakpoints.medium} {
    height: max-content;
  }
  ${Breakpoints.tablet} {
    height: max-content;
  }
`

export const GalleryContent = styled.div`
  width: 100%;
  position:absolute;
  display:flex;
  gap:1rem;
  ${Breakpoints.mobile} {
    position:relative;
    flex-direction: column;
    left:0;
    height: max-content;
  }
  ${Breakpoints.medium} {
    position:relative;
    flex-direction: column;
    height: max-content;
    left:-${Breakpoints.medium};
  }
  ${Breakpoints.tablet} {
    position:relative;
    flex-direction: column;
    height: max-content;
    left:-${Breakpoints.tablet};
  }
  ${Breakpoints.smDesktop} {
    width: 110%;
    left:-${Breakpoints.smDesktop};
  }
  ${Breakpoints.lgDesktop} {
    left:-${Breakpoints.lgDesktop};
  }
  ${Breakpoints.wide} {
    left:-${Breakpoints.wide};
  }
`

export const GalleryCaption = styled.p`
  width: 75%;
  font-family: ${Fonts.body};
  font-size:0.8rem;
  font-weight:300;
  line-height:1.35;
  letter-spacing:0.025rem;
  color: ${Colors.grey};
  ${Breakpoints.mobile} {
    width: 100% !important;
    margin-left: 0 !important;
  }
  ${Breakpoints.medium} {
    width: 100% !important;
    margin-left: ${Pad.medium} !important;
  }
`

export const GalleryItem = styled.div`
  display:flex;
  flex-direction:column;
  gap:10px;
  margin:1rem 0;
  width: ${props => props.width};
  img {
    width: 100%;
    height:auto;
  }
  ${Breakpoints.mobile} {
    width: 100%;
    img {
      width: 100vw;
      margin-left:-${Pad.mobile}
    }
  }
  ${Breakpoints.medium} {
    width: 100%;
    img {
      width: 100vw;
      margin-left:-${Pad.medium};
    }
  }
`

export const GalleryColumn1 = styled.div`
  flex:1.5;
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  ${GalleryItem} {
    &:nth-of-type(3) ${GalleryCaption} {
      width: 100%;
    }
  }
  ${Breakpoints.mobile} {
    flex-direction: column;
    flex:initial;
    ${GalleryItem} {
    &:nth-of-type(1) ${GalleryCaption},
    &:nth-of-type(2) ${GalleryCaption} { margin-left: ${Pad.mobile};}
    }
  }
  ${Breakpoints.medium} {
    flex-direction: column;
    flex:initial;
    ${GalleryItem} {
    &:nth-of-type(1) ${GalleryCaption},
    &:nth-of-type(2) ${GalleryCaption} { margin-left: ${Pad.medium};}
    }
  }
  ${Breakpoints.tablet} {
    ${GalleryItem} {
    &:nth-of-type(1) ${GalleryCaption},
    &:nth-of-type(2) ${GalleryCaption} { margin-left: ${Pad.tablet};}
    }
  }
  ${Breakpoints.smDesktop} {
    ${GalleryItem} {
    &:nth-of-type(1) ${GalleryCaption},
    &:nth-of-type(2) ${GalleryCaption} { margin-left: ${Pad.smDesktop};}
    }
  }
  ${Breakpoints.lgDesktop} {
    ${GalleryItem} {
    &:nth-of-type(1) ${GalleryCaption},
    &:nth-of-type(2) ${GalleryCaption} { margin-left: ${Pad.lgDesktop};}
    }
  }
  ${Breakpoints.wide} {
    ${GalleryItem} {
    &:nth-of-type(1) ${GalleryCaption},
    &:nth-of-type(2) ${GalleryCaption} { margin-left: ${Pad.wide};}
    }
  }
`
export const GalleryColumn2 = styled.div`
  flex:1;
  ${Breakpoints.mobile} {
    flex-direction: column;
    flex:initial;
  }
  ${Breakpoints.medium} {
    flex-direction: column;
    flex:initial;
  }
`