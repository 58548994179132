import { MobileNavComponent, MobileOverlay } from "./style"
import { Colors } from "../../../colours"
import { LgButton } from "../../../buttons"
import { useState } from "react"

const MobileNav = (props) => {
  
  const [open, setOpen] = useState('closed')
  const openOverlay = () => {
    setOpen('open')
  }
  const closeOverlay = () => {
    setOpen('closed')
  }

  return (
  <MobileNavComponent.Wrapper>
    <MobileNavComponent.Logo fill={props.color} />
    <MobileNavComponent.Icon fill={props.color} onClick={openOverlay}/>
    <MobileOverlay.Wrapper className={open}>
      <MobileOverlay.Nav>
        <MobileOverlay.Icon fill={Colors.black} onClick={closeOverlay}/>
      </MobileOverlay.Nav>
      <MobileOverlay.Logo fill={Colors.orange}/>
      <MobileOverlay.Menu>
        <MobileOverlay.MenuLink to='/'>Home</MobileOverlay.MenuLink>
        <MobileOverlay.MenuLink to='/work'>Work</MobileOverlay.MenuLink>
        <MobileOverlay.MenuLink to='/services'>Services</MobileOverlay.MenuLink>
        <MobileOverlay.MenuLink to='/contact'>Contact</MobileOverlay.MenuLink>
      </MobileOverlay.Menu>
      <MobileOverlay.Footer>
        <LgButton to='/contact' color={Colors.orange} hover={Colors.black} >Get quote</LgButton>
      </MobileOverlay.Footer>
    </MobileOverlay.Wrapper>
  </MobileNavComponent.Wrapper>
)}

export default MobileNav